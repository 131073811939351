import { Controller } from '@hotwired/stimulus';

/**
 * Show/hide menu items based on current user.
 * Also manage active/inactive menu items.
 */
export default class extends Controller {
    static classes = ['linkActive', 'linkInactive', 'iconActive'];

    static targets = ['link'];

    connect() {
        this.boundUpdateView = this.updateView.bind(this);
        document.addEventListener('userSessionUpdated', this.boundUpdateView);

        this.updateView();
    }

    disconnect() {
        document.removeEventListener('userSessionUpdated', this.boundUpdateView);
    }

    updateView() {
        const user = JSON.parse(sessionStorage.getItem('user'));

        const currentPath = window.location.href;
        this.linkTargets.forEach((link) => {
            if (currentPath.includes(link.getAttribute('href'))) {
                link.parentElement.classList.add(...this.linkActiveClasses);
                link.querySelector('i')?.classList.add(...this.iconActiveClasses);
                link.parentElement.classList.remove(...this.linkInactiveClasses);
            } else {
                link.parentElement.classList.remove(...this.linkActiveClasses);
                link.querySelector('i')?.classList.remove(...this.iconActiveClasses);
                link.parentElement.classList.add(...this.linkInactiveClasses);
            }
        });

        // eslint-disable-next-line no-restricted-syntax
        for (const [itemSlug, enabled] of Object.entries(user?.content.menu?.items || [])) {
            const menuItemElement = this.element.querySelector(`[data-account-menu-item="${itemSlug}"]`);
            if (menuItemElement === null) {
                continue;
            }

            if (enabled) {
                menuItemElement.classList.remove('tw-hidden');
            } else {
                menuItemElement.classList.add('tw-hidden');
            }
        }
    }
}
