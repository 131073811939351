import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        'input',
        'fileName',
        'fileExtension',
        'filePlaceholder',
        'remove',
        'removeInput',
        'trigger',
    ];

    static classes = ['empty', 'filled', 'inputEmpty', 'inputFilled'];

    static values = {
        file: String,
    };

    connect() {
        if (!this.hasInputTarget) {
            return;
        }

        this.boundHandleFileChanged = this.handleFileChanged.bind(this);
        this.inputTarget.addEventListener('change', this.boundHandleFileChanged);
    }

    fileValueChanged() {
        if (this.fileValue === '') {
            if (this.hasRemoveInputTarget) {
                this.removeInputTarget.value = 'true';
            }

            if (this.hasFilePlaceholderTarget) {
                this.filePlaceholderTarget.classList.remove('tw-hidden');
            }

            if (this.hasFileNameTarget) {
                this.fileNameTarget.classList.add('tw-hidden');
            }

            if (this.hasFileExtensionTarget) {
                this.fileExtensionTarget.classList.add('tw-hidden');
            }

            if (this.hasRemoveTarget) {
                this.removeTarget.classList.add('tw-hidden');
            }

            if (this.hasEmptyClass && this.hasFilledClass) {
                this.element.classList.remove(...this.filledClasses);
                this.element.classList.add(...this.emptyClasses);
            }

            if (this.hasTriggerTarget && this.hasInputEmptyClass && this.hasInputFilledClass) {
                this.triggerTarget.classList.remove(...this.inputFilledClasses);
                this.triggerTarget.classList.add(...this.inputEmptyClasses);
            }

            return;
        }

        if (this.hasRemoveInputTarget) {
            this.removeInputTarget.value = 'false';
        }

        if (this.hasFilePlaceholderTarget) {
            this.filePlaceholderTarget.classList.add('tw-hidden');
        }

        const parts = this.fileValue?.split('.');

        if (parts[0] && this.hasFileNameTarget) {
            this.fileNameTarget.innerText = parts[0];
            this.fileNameTarget.classList.remove('tw-hidden');
        }

        if (parts[1] && this.hasFileExtensionTarget) {
            this.fileExtensionTarget.innerText = `.${parts[1]}`;
            this.fileExtensionTarget.classList.remove('tw-hidden');
        }

        if (this.hasRemoveTarget) {
            this.removeTarget.classList.remove('tw-hidden');
        }

        if (this.hasEmptyClass && this.hasFilledClass) {
            this.element.classList.remove(...this.emptyClasses);
            this.element.classList.add(...this.filledClasses);
        }

        if (this.hasTriggerTarget && this.hasInputEmptyClass && this.hasInputFilledClass) {
            this.triggerTarget.classList.remove(...this.inputEmptyClasses);
            this.triggerTarget.classList.add(...this.inputFilledClasses);
        }
    }

    selectFile() {
        if (!this.hasInputTarget) {
            return;
        }

        this.inputTarget.click();
    }

    removeFile() {
        if (!this.hasInputTarget) {
            return;
        }

        this.inputTarget.value = null;
        this.inputTarget.dispatchEvent(new Event('change'));
    }

    handleFileChanged() {
        if (this.inputTarget.value) {
            this.fileValue = this.inputTarget.value.split('\\').pop();
        } else {
            this.fileValue = '';
        }
    }
}
