import DropdownController from '../dropdown-controller';

export default class extends DropdownController {
    static values = {
        id: Number,
        showLink: String,
        updateLink: String,
        republishLink: String,
        ordersLink: String,
        applicationsLink: String,
    };

    static targets = ['showLink', 'updateLink', 'republishLink', 'ordersLink', 'applicationsLink'];

    idValueChanged() {
        this.applicationsLinkTarget.href = this.idValue ? this.applicationsLinkValue.replace('{id}', this.idValue) : '#';

        if (this.hasUpdateLinkTarget) {
            this.updateLinkTarget.href = this.idValue ? this.updateLinkValue.replace('{id}', this.idValue) : '#';
        }

        if (this.hasRepublishLinkTarget) {
            this.republishLinkTarget.href = this.idValue ? this.republishLinkValue.replace('{id}', this.idValue) : '#';
        }

        if (this.hasOrdersLinkTarget) {
            this.ordersLinkTarget.href = this.idValue ? this.ordersLinkValue.replace('{id}', this.idValue) : '#';
        }
    }

    showLinkValueChanged() {
        if (this.hasShowLinkTarget) {
            this.showLinkTarget.href = this.showLinkValue;
        }
    }

    askArchive({ params }) {
        const modal = document.querySelector(params.modalSelector);
        if (!modal) {
            return;
        }

        const modalController = this.application.getControllerForElementAndIdentifier(modal, 'modal--confirm');
        if (!modalController) {
            return;
        }

        // Must be declared before "archive" function in order to unbind later
        let unbindEvents;

        const archive = async (event) => {
            unbindEvents(event);

            modalController.showLoader();

            const showErrorToaster = () => {
                import(/* webpackChunkName: "toaster" */ '../../components/toaster/toaster').then((component) => {
                    component.default.display('error', "Nous ne sommes pas en mesure d'archiver votre annonce.");
                });
            };

            fetch(`/compte/espace-recruteur/adverts/${this.idValue}/archive.json`, {
                method: 'PATCH',
            }).then((response) => {
                if (!response.ok) {
                    showErrorToaster();

                    // eslint-disable-next-line no-console
                    console.error(`Unable to archive advert "${this.idValue}"`);

                    return;
                }

                this.dispatch('advert-archived');
            }).catch((e) => {
                showErrorToaster();

                // eslint-disable-next-line no-console
                console.error(`Unable to archive advert "${this.idValue}"`, e);
            }).finally(() => {
                modalController.close();
                modalController.hideLoader();
            });
        };

        unbindEvents = (event) => {
            event.target.removeEventListener('modal--confirm:confirm', archive);
            event.target.removeEventListener('modal--confirm:cancel', unbindEvents);
        };

        modal.addEventListener('modal--confirm:confirm', archive);
        modal.addEventListener('modal--confirm:cancel', unbindEvents);

        modalController.open();
    }
}
