import adsIdConstants from '../../utils/adsIdConstants';

const ads = {
    siteIdElement: document.querySelector('[data-adv-siteid]'),
    pageIdElement: document.querySelector('[data-adv-pageid]'),
    targetElement: document.querySelector('[data-adv-target]'),
    getSiteId: null,
    getPageId: null,
    getTarget: null,
    getFormatIds: document.querySelectorAll('[data-adv-formatid]'),

    init() {
        if (this.siteIdElement) {
            this.getSiteId = Number(this.siteIdElement.getAttribute('data-adv-siteid'));
        }
        if (this.pageIdElement) {
            this.getPageId = Number(this.pageIdElement.getAttribute('data-adv-pageid'));
        }
        if (this.targetElement) {
            this.getTarget = this.targetElement.getAttribute('data-adv-target');
        }
        if (this.getSiteId !== null && this.getPageId !== null && this.getTarget !== null) {
            this.AdsOneCall();
            this.Adsrender();
        }
    },
    AdsOneCall() {
        const formats = [];

        Object.values(this.getFormatIds).map((formatId) => {
            formats.push({
                id: Number(formatId.getAttribute('data-adv-formatid')),
                tagId: formatId.getAttribute('data-adv-tagid'),
            });
        });

        const filtered = formats.filter((item) => {
            return adsIdConstants.indexOf(item.id) === -1;
        });

        const filteredLazyload = formats.filter((item) => {
            return adsIdConstants.indexOf(item.id) !== -1;
        });

        if (typeof window.sas !== 'undefined') {
            Object.values(filteredLazyload).map((format) => {
                window.sas.cmd.push(() => {
                    window.sas.call('std', {
                        siteId: this.getSiteId,
                        pageId: this.getPageId,
                        formatId: format.id,
                        tagId: format.tagId,
                        target: this.getTarget,
                    });
                });
            });

            window.sas.cmd.push(() => {
                window.sas.call('onecall', {
                    siteId: this.getSiteId,
                    pageId: this.getPageId,
                    formats: filtered,
                    target: this.getTarget,
                });
            });
        }
    },
    Adsrender() {
        Object.values(this.getFormatIds).map((formatId) => {
            const id = Number(formatId.getAttribute('data-adv-formatid'));
            if (typeof sas !== 'undefined') {
                window.sas.cmd.push(() => {
                    window.sas.render(id);
                });
            }
        });
    },
};

export default ads.init();
