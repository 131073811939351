import { crypto } from '../../utils/crypto';

const invibes = {
    setCookie(user) {
        const { email, optins } = user;
        if (!optins.commercial || this.cookieExists('invibes')) {
            return;
        }

        this.createCookie('invibes', email).catch((error) => {
            // eslint-disable-next-line no-console
            console.error('Unable to create Invives cookie', { error });
        });
    },
    async createCookie(name, email) {
        const hashedEmail = await this.normalizeAndHashEmail(email);
        const dateExpiration = new Date();
        dateExpiration.setTime(dateExpiration.getTime() + (30 * 24 * 60 * 60 * 1000));
        const expiration = `expires=${dateExpiration.toUTCString()}`;
        document.cookie = `${name}=${hashedEmail}; ${expiration}; SameSite=Lax; path=/; Secure`;
    },
    cookieExists(cookieName) {
        return document.cookie.split('; ').some((row) => row.startsWith(`${cookieName}=`));
    },
    async normalizeAndHashEmail(email) {
        // eslint-disable-next-line no-param-reassign
        email = email.trim().toLowerCase();

        if (!email.endsWith('@gmail.com')) {
            return crypto.sha256(email);
        }

        const username = email.split(email.includes('+') ? '+' : '@gmail.com')[0].replaceAll('.', '');

        return crypto.sha256(`${username}@gmail.com`);
    },
};

// eslint-disable-next-line import/prefer-default-export
export { invibes };
