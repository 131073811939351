import SimpleLightbox from 'simplelightbox';

const imageMediaComponent = {
    init() {
        const lightbox = new SimpleLightbox('.zoomable');
        lightbox.on('error.simplelightbox', (e) => {
            // eslint-disable-next-line no-console
            console.error('erreur lightbox', e);
        });
    },
};

export default imageMediaComponent;
