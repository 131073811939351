import { Controller } from '@hotwired/stimulus';

import { transition } from '../utils/transition';

export default class extends Controller {
    static targets = ['menu'];

    static values = {
        open: Boolean,
    };

    async openValueChanged() {
        await transition(this.menuTarget, this.openValue);
    }

    async toggle() {
        this.openValue = !this.openValue;
        // We need to trigger transition here in order to allow other controllers to get menu's width.
        await transition(this.menuTarget, this.openValue);
    }

    async show() {
        this.openValue = true;
        // We need to trigger transition here in order to allow other controllers to get menu's width.
        await transition(this.menuTarget, this.openValue);
    }

    hide(event) {
        if (!this.element.contains(event.target) && this.openValue) {
            this.openValue = false;
        }
    }
}
