import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        applications: Array,
    };

    async connect() {
        if (typeof window.luxon === 'undefined') {
            // This dependency is required by tabulator-tables and its `date` filter
            window.luxon = await import('luxon');
        }

        const { TabulatorFull } = await import('tabulator-tables');

        this.Tabulator = TabulatorFull;

        await this.setupTabulator();

        const boundUpdateColumnWidth = this.updateColumnWidth.bind(this);

        this.resizeObserver = new ResizeObserver(boundUpdateColumnWidth);
        this.resizeObserver.observe(this.element);
    }

    disconnect() {
        this.resizeObserver.disconnect();
    }

    async setupTabulator() {
        const { default: responsiveCollapse } = await import('../../tabulator-tables/responsiveCollapse');
        const { default: langs } = await import('../../tabulator-tables/langs');

        this.table = new this.Tabulator(this.element, {
            debugEventsExternal: true,
            locale: 'fr',
            langs,
            pagination: true,
            paginationSize: 50,
            data: this.applicationsValue,
            placeholder: "Vous n'avez pas de candidatures",
            headerSortElement: (column, dir) => {
                switch (dir) {
                    case 'asc':
                        return '<i class="fal fa-arrow-alt-up tw-text-xl tw-text-primary"></i>';
                    case 'desc':
                        return '<i class="fal fa-arrow-alt-down tw-text-xl tw-text-primary"></i>';
                    default:
                        return '<i class="fal fa-sort tw-text-xl tw-text-primary"></i>';
                }
            },
            layout: 'fitData',
            responsiveLayout: 'collapse',
            responsiveLayoutCollapseStartOpen: false,
            columns: [
                {
                    field: 'responsiveCollapse',
                    formatter: responsiveCollapse,
                    width: 50,
                    hozAlign: 'center',
                    responsive: 0,
                    resizable: false,
                    headerSort: false,
                },
                {
                    title: 'Prénom',
                    field: 'first_name',
                    responsive: 0,
                    resizable: false,
                },
                {
                    title: 'Nom',
                    field: 'last_name',
                    resizable: false,
                },
                {
                    title: 'Date de postulation',
                    field: 'date',
                    formatter: 'datetime',
                    formatterParams: {
                        inputFormat: 'iso',
                        outputFormat: 'dd/MM/yyyy',
                    },
                    resizable: false,
                    cssClass: '!tw-border-r-0',
                },
                {
                    title: 'CV',
                    field: 'resume',
                    formatter: (cell) => {
                        if (cell.getValue()) {
                            return `<a class="tw-text-primary tw-underline tw-italic" href=${cell.getValue()}>Télécharger</a>`;
                        }

                        return 'Aucun fichier transmis';
                    },
                    resizable: false,
                },
                {
                    title: 'Lettre de motivation',
                    field: 'cover_letter',
                    formatter: (cell) => {
                        if (cell.getValue()) {
                            return `<a class="tw-text-primary tw-underline tw-italic" href=${cell.getValue()}>Télécharger</a>`;
                        }

                        return "Le candidat n'a pas laissé de lettre de motivation.";
                    },
                    resizable: false,
                },
                {
                    title: 'Message',
                    field: 'message',
                    formatter: (cell) => {
                        return `<span class="message">${cell.getValue() ?? "Le candidat n'a pas laissé de message."}</span>`;
                    },
                    resizable: false,
                },
            ],
        });

        this.table.on('tableBuilt', () => {
            this.updateColumnWidth();
        });
    }

    updateColumnWidth() {
        if (!this.table.initialized) {
            return;
        }

        const responsiveCollapseElement = this.table.getColumn('responsiveCollapse').getElement();

        const getHorizontalBorderWidth = (element) => {
            const computedStyle = getComputedStyle(element);

            return (parseInt(computedStyle.borderLeftWidth, 10) || 0) + (parseInt(computedStyle.borderRightWidth, 10) || 0);
        };

        const getElementWidth = (element) => element.offsetWidth + getHorizontalBorderWidth(element);

        const getColumnWidth = (displayableColumns) => {
            const availableWidth = this.element.offsetWidth
                - getHorizontalBorderWidth(this.element)
                - getElementWidth(responsiveCollapseElement);
            return (availableWidth / displayableColumns);
        };

        let visibleColumns = 0;

        let displayableColumns = 3;
        if (window.innerWidth <= 540) {
            displayableColumns = 1;
        } else if (window.innerWidth <= 640) {
            displayableColumns = 2;
        }

        this.table.getColumns().forEach((column) => {
            if (column.getField() === 'responsiveCollapse') {
                return;
            }

            if (visibleColumns < displayableColumns) {
                const columnWidth = getColumnWidth(displayableColumns);

                column.setWidth(columnWidth);

                // eslint-disable-next-line no-plusplus
                visibleColumns++;
            } else {
                column.setWidth(true);
            }
        });

        // Ensure columns are hidden/shown after width changes
        this.table.columnManager.layoutRefresh();
    }
}
