import { Controller } from '@hotwired/stimulus';

import { transition } from '../../utils/transition';

export default class extends Controller {
    static targets = ['link'];

    delete() {
        if (this.element.getAttributeNames().some((attribute) => attribute.startsWith('data-transition-'))) {
            transition(this.element, false);
        }

        fetch(this.linkTarget.href, {
            method: 'DELETE',
            credentials: 'include',
        }).catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error);
        });
    }
}
