import { Controller } from '@hotwired/stimulus';
import axios from 'axios';

import dataLayerEvents from '../../components/dataLayerEvents/dataLayerEvents';

/**
 * Manage current user notifications.
 */
export default class extends Controller {
    static targets = ['list', 'loader', 'unseenBadge'];

    static outlets = ['header'];

    connect() {
        this.loading = false;
        this.notificationsLoaded = false;

        this.boundUpdateView = this.updateView.bind(this);
        document.addEventListener('userSessionUpdated', this.boundUpdateView);

        this.updateView();
    }

    disconnect() {
        document.removeEventListener('userSessionUpdated', this.boundUpdateView);
    }

    updateView() {
        const user = JSON.parse(sessionStorage.getItem('user'));
        if (!user?.content?.connected) {
            return;
        }

        if (this.hasListTarget) {
            this.fetchNotifications();
        }
    }

    fetchNotifications() {
        if (this.loading || this.notificationsLoaded) {
            return;
        }

        this.showLoader();

        axios
            .get('/compte/notifications.html')
            .then((response) => {
                this.listTarget.innerHTML = response.data;
                dataLayerEvents.refreshDOM(this.listTarget);
            })
            .finally(() => {
                this.notificationsLoaded = true;

                if (this.hasUnseenBadgeTarget && this.hasHeaderOutlet) {
                    this.headerOutlet.showNotificationBadge();
                }

                this.hideLoader();
            });
    }

    markNotificationsAsSeen() {
        // Nothing to update, initial fetch is not ready
        if (!this.notificationsLoaded) {
            return;
        }

        // Nothing to update, all notifications have been seen
        if (!this.hasUnseenBadgeTarget) {
            if (this.hasHeaderOutlet) {
                this.headerOutlet.hideNotificationBadge();
            }

            return;
        }

        // eslint-disable-next-line max-len
        const userNotificationIds = this.unseenBadgeTargets.map((element) => parseInt(element.getAttribute('data-user-notification-id'), 10));

        axios.put(
            '/compte/notifications.html',
            { userNotificationIds },
        ).then(() => {
            if (this.hasHeaderOutlet) {
                this.headerOutlet.hideNotificationBadge();
            }
        });
    }

    removeUnseenNotificationBadge() {
        this.unseenBadgeTargets.forEach((element) => {
            element.remove();
        });
    }

    showLoader() {
        this.loading = true;
        if (this.hasLoaderTarget) {
            this.loaderTarget.classList.remove('tw-hidden');
        }
    }

    hideLoader() {
        this.loading = false;
        if (this.hasLoaderTarget) {
            this.loaderTarget.classList.add('tw-hidden');
        }
    }
}
