import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        id: String,
    };

    static targets = [
        'studyLevel',
        'studySpeciality',
        'studyStream',
        'studyTechStream',
        'studyHigherStream',
    ];

    connect() {
        this.toggleRelatedFields();
        this.toggleRelatedChildrenFields();
    }

    toggleRelatedFields() {
        if (!this.hasStudyLevelTarget) {
            return;
        }

        this.studyLevelTarget.classList.remove('tw-hidden');

        const { selectedOptions } = this.studyLevelTarget.querySelector('select');
        // eslint-disable-next-line max-len
        const isStudySpecialityRequired = [...selectedOptions].some((selectedOption) => (selectedOption.getAttribute('data-study-speciality-required')));
        // eslint-disable-next-line max-len
        const isStudyStreamRequired = [...selectedOptions].some((selectedOption) => (selectedOption.getAttribute('data-study-stream-required')));

        const toggleContainer = (container, required) => {
            if (required) {
                container.classList.remove('tw-hidden');
            } else {
                container.classList.add('tw-hidden');
            }
        };

        this.studySpecialityTargets.forEach((container) => {
            toggleContainer(container, isStudySpecialityRequired);
        });

        this.studyStreamTargets.forEach((container) => {
            toggleContainer(container, isStudyStreamRequired);
        });
    }

    toggleRelatedChildrenFields() {
        if (!this.hasStudyLevelTarget) {
            return;
        }

        this.studyLevelTarget.classList.remove('tw-hidden');

        const { selectedOptions } = this.studyLevelTarget.querySelector('select');
        // eslint-disable-next-line max-len
        const isStudySpecialityRequired = [...selectedOptions].some((selectedOption) => (selectedOption.getAttribute('data-study-speciality-required')));
        // eslint-disable-next-line max-len
        const isStudyTechStreamRequired = [...selectedOptions].some((selectedOption) => (selectedOption.getAttribute('data-study-tech-stream-required')));
        // eslint-disable-next-line max-len
        const isStudyHigherStreamRequired = [...selectedOptions].some((selectedOption) => (selectedOption.getAttribute('data-study-higher-stream-required')));

        const toggleContainer = (container, required) => {
            if (required) {
                container.classList.remove('tw-hidden');
            } else {
                container.classList.add('tw-hidden');
            }
        };

        this.studySpecialityTargets.forEach((container) => {
            toggleContainer(container, isStudySpecialityRequired);
        });

        this.studyTechStreamTargets.forEach((container) => {
            toggleContainer(container, isStudyTechStreamRequired);
        });

        this.studyHigherStreamTargets.forEach((container) => {
            toggleContainer(container, isStudyHigherStreamRequired);
        });
    }
}
