import { invibes } from '../../invibesComponent/invibes';

const user = {
    response: null,
    init() {
        this.makeRequest('GET', `${MY_LETUDIANT_URL}/api/users/me`).then((res) => {
            this.response = JSON.parse(res);
            sessionStorage.setItem('user', res);

            document.dispatchEvent(new CustomEvent('userSessionUpdated'));

            this.dataLayerPushUser();
            this.SddanPushuser();

            if (this.response?.content?.connected) {
                invibes.setCookie(this.response.content.user);
            }

            if (this.response.content.mustRequalify) {
                window.dispatchEvent(new CustomEvent('load-study-level-modal'));
            }
        }).catch((err) => {
            // eslint-disable-next-line no-console
            console.error(err);
        });
    },
    makeRequest(method, url) {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open(method, url);
            xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
            xhr.withCredentials = true;

            xhr.onload = function handleRequestLoad() {
                if (this.status >= 200 && this.status < 300) {
                    resolve(xhr.response);
                } else {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    reject({
                        status: this.status,
                        statusText: xhr.statusText,
                    });
                }
            };
            xhr.onerror = function handleRequestError() {
                // eslint-disable-next-line prefer-promise-reject-errors
                reject({
                    status: this.status,
                    statusText: xhr.statusText,
                });
            };
            xhr.send();
        });
    },
    isConnected() {
        return this.response && this.response.content ? this.response.content.connected : false;
    },
    SddanPushuser() {
        if (typeof Sddan === 'undefined') {
            return;
        }

        const userObject = { info: {} };
        if (this.isConnected()) {
            const userData = this.response.content.user;

            if (typeof userData.md5_email !== 'undefined') {
                userObject.info.hd_m = userData.md5_email;
            }
            if (typeof userData.hashed_email !== 'undefined') {
                userObject.info.hd_s256 = userData.hashed_email;
            }
            if (typeof userData.zipcode !== 'undefined') {
                userObject.info.postal_code = userData.zipcode;
            }
            if (typeof userData.gender !== 'undefined') {
                userObject.info.uf_gender = userData.gender;
            }
            if (typeof userData.birthday !== 'undefined') {
                userObject.info.uf_bday = userData.birthday;
            }

            const params = [
                'pa=21811',
                `hd_m=${userObject.info.hd_m}`,
                `hd_s256=${userObject.info.hd_s256}`,
                `postal_code=${userObject.info.postal_code}`,
                `uf_gender=${userObject.info.uf_gender}`,
                `uf_bday=${userObject.info.uf_bday}`,
            ];
            const sddanIMG = document.createElement('img');
            sddanIMG.height = '1';
            sddanIMG.width = '1';
            sddanIMG.style.display = 'none';
            // eslint-disable-next-line max-len
            sddanIMG.setAttribute(
                'data-cmp-src',
                `https://js.sddan.com/HDM.d?gdpr=\${SDDAN_GDPR_APPLIES}&gdpr_consent=\${SDDAN_GDPR_CONSENT}&${params.join('&')}`,
            );
            document.getElementsByTagName('body')[0].appendChild(sddanIMG);
            try {
                // eslint-disable-next-line no-undef
                Sddan.cmp.triggerTM();
            } catch (e) {
                throw new Error('Erreur:', e);
            }
        }
    },
    dataLayerPushUser() {
        if (typeof window.dataLayer === 'undefined') {
            return;
        }

        const userObject = {
            event: 'etuEventUser',
        };
        if (this.isConnected()) {
            userObject.userLogged = 'Oui';
            const userData = this.response.content.user;
            if (userData.userProfile) {
                userObject.userProfile = userData.userProfile;
            }
            if (userData.userNiveauEtude) {
                userObject.userNiveauEtude = userData.userNiveauEtude;
            }
            if (userData.userFiliere) {
                userObject.userFiliere = userData.userFiliere;
            }
            if (userData.userDomaine) {
                userObject.userDomaine = userData.userDomaine;
            }
            if (userData.userNewsletter) {
                userObject.userNewsletter = userData.userNewsletter;
            }
        } else {
            userObject.userLogged = 'Non';
        }

        window.dataLayer.push(userObject);
    },
};

export default user;
