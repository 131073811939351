import { Controller } from '@hotwired/stimulus';

/**
 * Show/hide elements if current user is anonymous/authenticated.
 * Be aware that code will still be in DOM.
 */
export default class extends Controller {
    static targets = ['anonymous', 'authenticated'];

    connect() {
        this.authenticatedTargets.forEach((element) => {
            element.classList.add('tw-hidden');
        });

        this.boundUpdateView = this.toggleContent.bind(this);
        document.addEventListener('userSessionUpdated', this.boundUpdateView);

        this.toggleContent();
    }

    disconnect() {
        document.removeEventListener('userSessionUpdated', this.boundUpdateView);
    }

    toggleContent() {
        const user = JSON.parse(sessionStorage.getItem('user'));

        const isConnected = user?.content?.connected;

        this.anonymousTargets.forEach((element) => {
            // eslint-disable-next-line no-unused-expressions
            isConnected ? element.classList.add('tw-hidden') : element.classList.remove('tw-hidden');
        });

        this.authenticatedTargets.forEach((element) => {
            // eslint-disable-next-line no-unused-expressions
            isConnected ? element.classList.remove('tw-hidden') : element.classList.add('tw-hidden');
        });
    }
}
