import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['company', 'individual'];

    static values = {
        profile: String,
    };

    profileValueChanged(currentProfile, oldProfile) {
        if (currentProfile === oldProfile) {
            return;
        }

        if (this.profileValue === 'RECRUITER_COMPANY') {
            this.companyTargets.forEach((target) => {
                target.classList.remove('tw-hidden');
            });

            this.individualTargets.forEach((target) => {
                target.classList.add('tw-hidden');
            });
        } else {
            this.individualTargets.forEach((target) => {
                target.classList.remove('tw-hidden');
            });

            this.companyTargets.forEach((target) => {
                target.classList.add('tw-hidden');
            });
        }
    }

    setProfileCompany() {
        this.profileValue = 'RECRUITER_COMPANY';
    }

    setProfileIndividual() {
        this.profileValue = 'RECRUITER_INDIVIDUAL';
    }
}
