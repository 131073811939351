import axios from 'axios';
import _ from 'lodash';

const ssoAutocomplete = {
    abortController: new AbortController(),
    init() {
        const setAutocompleteValue = ({ value, label, autocomplete }) => {
            document.querySelector(`[data-autocomplete-name=${autocomplete.id}]`).value = value;
            document.querySelector(`#${autocomplete.id}`).value = label;
            const autocompleteList = document.querySelector(`#sso-autocomplete-list-${autocomplete.id}`);
            if (autocompleteList) {
                autocompleteList.classList.add('tw-hidden');
            }
        };

        const autocompletes = document.querySelectorAll('.sso-autocomplete');

        autocompletes.forEach((autocomplete) => {
            autocomplete.addEventListener('keyup', _.debounce((e) => {
                if (e.key === 'Backspace') {
                    return;
                }

                this.abortController.abort();
                this.abortController = new AbortController();

                document.querySelector(`[data-autocomplete-name=${autocomplete.id}]`).value = null;

                const search = e.target.value;
                const url = `${MY_LETUDIANT_URL}/api/proxy${autocomplete.getAttribute('data-sso-autocomplete-url')}`;

                axios.get(url, {
                    headers: { 'X-Requested-With': 'XMLHttpRequest' },
                    params: { q: search },
                    signal: this.abortController.signal,
                })
                    .then((response) => {
                        const autocompleteList = document.querySelector(`#sso-autocomplete-list-${autocomplete.id}`);
                        let dom = '<ul>';

                        response.data.content.forEach((item) => {
                            dom += '<li '
                                + `id="${autocomplete.id}-${item.selectOptionValue}" `
                                + 'class="si-item" '
                                + `data-sso-autocomplete-label="${item.selectOptionLabel}" `
                                + `data-sso-autocomplete-value="${item.selectOptionValue}"`
                                + `>${item.selectOptionLabel}</li>`;
                        });

                        dom += '</ul>';
                        autocompleteList.innerHTML = dom;
                        autocompleteList.classList.remove('tw-hidden');

                        if (response.data.content.length === 0) {
                            const defaultValueEnabled = !autocomplete.hasAttribute('data-autocomplete-no-default-value');
                            setAutocompleteValue({
                                value: defaultValueEnabled ? autocomplete.getAttribute('data-autocomplete-empty-value') : '',
                                label: defaultValueEnabled ? autocomplete.getAttribute('data-autocomplete-empty-label') : '',
                                autocomplete,
                            });
                        } else if (response.data.content.length === 1) {
                            const item = response.data.content[0];
                            setAutocompleteValue({
                                value: item.selectOptionValue,
                                label: item.selectOptionLabel,
                                autocomplete,
                            });
                        } else {
                            response.data.content.forEach((item) => {
                                const li = document.querySelector(`#${autocomplete.id}-${item.selectOptionValue}`);
                                if (li) {
                                    li.addEventListener('click', (event) => {
                                        setAutocompleteValue({
                                            value: event.target.getAttribute('data-sso-autocomplete-value'),
                                            label: event.target.getAttribute('data-sso-autocomplete-label'),
                                            autocomplete,
                                        });
                                    });
                                }
                            });
                        }
                    });
            }, 400));
        });
    },
};

export default ssoAutocomplete;
