// Should be calculated from each slide, but this is a quick fix
const BORDERS_WIDTH = 2;

export default function HorizontalOffset({ params, extendParams, swiper }) {
    extendParams({
        horizontalOffset: {
            from: null,
        },
    });

    function getSliderWidths() {
        const containerWidth = swiper.size;
        const slidesWidth = swiper.slidesSizesGrid.reduce((total, slide) => total + slide, 0);

        let targetWidth = 0;
        let targetPaddingLeft = 0;
        // When using a full width slider, but we want to visually align with another container.
        // This attribute should be used with a CSS selector
        if (swiper.params.horizontalOffset.from) {
            const target = swiper.params.horizontalOffset.from;
            const targetStyle = target.currentStyle || window.getComputedStyle(target);

            targetWidth = target.offsetWidth;
            targetPaddingLeft = parseInt(targetStyle.paddingLeft, 10);
        }

        const marginWidth = (containerWidth - targetWidth) / 2;

        return { slidesWidth, marginWidth, targetPaddingLeft, targetWidth };
    }

    params.slidesOffsetBefore = () => {
        const { marginWidth, targetPaddingLeft } = getSliderWidths();

        return marginWidth + targetPaddingLeft - BORDERS_WIDTH;
    };

    params.slidesOffsetAfter = () => {
        const { slidesWidth, marginWidth, targetPaddingLeft, targetWidth } = getSliderWidths();

        if (slidesWidth <= targetWidth) {
            return -marginWidth - targetPaddingLeft + BORDERS_WIDTH;
        }

        return marginWidth + targetPaddingLeft - BORDERS_WIDTH;
    };
}
