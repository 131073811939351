const dataLayerEvents = {
    elements: document.querySelectorAll('[data-layer-event]'),
    availableParameters: [
        { paramKey: 'event', dataAttribute: 'data-layer-event' },
        { paramKey: 'eventCat', dataAttribute: 'data-layer-event-cat' },
        { paramKey: 'eventAct', dataAttribute: 'data-layer-event-act' },
        { paramKey: 'eventLab', dataAttribute: 'data-layer-event-lab' },
        { paramKey: 'mediaType', dataAttribute: 'data-layer-event-media-type' },
        { paramKey: 'articleType', dataAttribute: 'data-layer-event-article-type' },
        { paramKey: 'blocName', dataAttribute: 'data-layer-event-bloc-name' },
        { paramKey: 'keyword', dataAttribute: 'data-layer-event-keyword' },
        { paramKey: 'offreType', dataAttribute: 'data-layer-event-offre-type' },
        { paramKey: 'offreDate', dataAttribute: 'data-layer-event-offre-date' },
        { paramKey: 'salonName', dataAttribute: 'data-layer-event-salon-name' },
        { paramKey: 'ecoleName', dataAttribute: 'data-layer-event-ecole-name' },
        { paramKey: 'filièreEtude', dataAttribute: 'data-layer-event-filiere-etude' },
        { paramKey: 'secteurEtude', dataAttribute: 'data-layer-event-secteur-etude' },
        { paramKey: 'opsName', dataAttribute: 'data-layer-event-ops-name' },
        { paramKey: 'socialNet', dataAttribute: 'data-layer-event-social-net' },
        { paramKey: 'socialAct', dataAttribute: 'data-layer-event-social-act' },
        { paramKey: 'socialTarget', dataAttribute: 'data-layer-event-social-target' },
        { paramKey: 'onsitead_type', dataAttribute: 'data-onsitead-type' },
        { paramKey: 'onsitead_campaign', dataAttribute: 'data-onsitead-campaign' },
        { paramKey: 'onsitead_format', dataAttribute: 'data-onsitead-format' },
        { paramKey: 'onsitead_general_placement', dataAttribute: 'data-onsitead-general-placement' },
    ],
    init() {
        if (this.elements.length == 0 || typeof window.dataLayer == 'undefined') {
            return;
        }

        this.elements.forEach((element) => {
            this.bindEvent(element);
        });
    },
    refreshDOM(html) {
        html.querySelectorAll('[data-layer-event]').forEach((element) => {
            this.bindEvent(element);
        });
    },
    bindEvent(element) {
        if (element.hasAttribute('data-onsitead-campaign')) {
            this.bindOnsiteAdCampaignEvents(element);

            return;
        }

        const eventType = element.hasAttribute('data-layer-event-type') ? element.getAttribute('data-layer-event-type') : 'click';

        element.addEventListener(eventType, () => {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push(this.getEventParameters(element));
        });
    },
    bindOnsiteAdCampaignEvents(element) {
        element.addEventListener('click', () => {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push(this.getEventParameters(element));
            if (element.getAttribute('data-layer-event') !== 'etuEventPromoClick') {
                window.dataLayer.push({ ...this.getEventParameters(element), event: 'etuEventPromoClick' });
            }
        });

        // use intersection observer to track link visibility
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({ ...this.getEventParameters(element), event: 'etuEventPromoImpression' });
                    observer.unobserve(element);
                }
            });
        });

        observer.observe(element);
    },
    getEventParameters(element) {
        if (element.tagName === 'SELECT') {
            const selectedItems = [];
            // eslint-disable-next-line no-restricted-syntax
            for (const selectedItem of element.selectedOptions) {
                selectedItems.push(selectedItem.value);
            }

            element.setAttribute('data-layer-event-lab', selectedItems.join(';'));
        }

        const eventParameters = {};

        this.availableParameters.map((parameter) => {
            if (element.hasAttribute(parameter.dataAttribute)) {
                eventParameters[parameter.paramKey] = element.getAttribute(parameter.dataAttribute);
            }
        });

        if (element.hasAttribute('data-layer-event-queryselector')) {
            const targetNode = document.querySelector(element.getAttribute('data-layer-event-queryselector'));
            let paramName = 'eventLab';
            let paramValue = '';

            if (targetNode !== null) {
                if (targetNode.tagName == 'INPUT') {
                    paramValue = targetNode.value;
                } else if (targetNode.tagName == 'H1') {
                    paramValue = targetNode.innerText;
                }
            }

            if (element.hasAttribute('data-layer-event-queryselector-param-name')) {
                paramName = element.getAttribute('data-layer-event-queryselector-param-name');
            }

            eventParameters[paramName] = paramValue;
        }

        return eventParameters;
    },
};

export default dataLayerEvents;
