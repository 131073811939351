import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['block', 'checkbox', 'disabledBtn', 'pending'];

    static classes = ['selected', 'disabled'];

    static values = {
        id: Number,
        label: String,
        disabled: { type: Boolean, default: false },
        endpoint: String,
        checked: { type: Boolean, default: false },
    };

    connect() {
        if (this.checkedValue) {
            this.checkboxTarget.checked = true;
            this.applySelectedStyle();
        }

        if (this.disabledValue) {
            this.applyDisabledStyle();
        }

        this.dispatch('update-count');
    }

    applyDisabledStyle() {
        this.checkboxTarget.checked = false;
        this.checkboxTarget.disabled = true;

        this.blockTarget.classList.add(...this.disabledClasses);
        this.blockTarget.classList.remove('tw-border-black');

        if (this.hasDisabledBtnTarget) {
            this.disabledBtnTarget.querySelector('i').classList.add('fa-arrow-rotate-right');
            this.disabledBtnTarget.querySelector('i').classList.remove('fa-xmark');
        }
    }

    toggleDisabled() {
        this.disabledValue = !this.disabledValue;
        this.checkboxTarget.checked = false;

        if (this.disabledValue) {
            this.blockTarget.classList.add(...this.disabledClasses);
            this.checkboxTarget.disabled = true;
        } else {
            this.blockTarget.classList.remove(...this.disabledClasses);
            this.checkboxTarget.disabled = false;
        }

        this.blockTarget.classList.toggle('tw-border-black');

        if (this.hasDisabledBtnTarget) {
            this.disabledBtnTarget.querySelector('i').classList.toggle('fa-arrow-rotate-right');
            this.disabledBtnTarget.querySelector('i').classList.toggle('fa-xmark');
        }

        this.updateData(false);
    }

    toggleCheckbox(event) {
        if (this.disabledValue || event.target.tagName === 'A') {
            event.stopPropagation();
            return;
        }
        if (event.target.tagName !== 'INPUT') {
            this.checkboxTarget.checked = !this.checkboxTarget.checked;
        }
        this.applySelectedStyle();
        this.updateData();
    }

    updateData(withPending = true) {
        // must set param before pending call because disabledValue will be affected
        const params = {
            checked: this.checkboxTarget.checked,
            hidden: this.disabledValue,
            checklistItem: this.idValue,
        };
        if (withPending) {
            this.applyPendingStyle();
        }

        fetch(this.endpointValue, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params),
        }).then((response) => {
            if (!response.ok) {
                throw new Error(`${response.status}`);
            }
            this.updateDataLayerEventLab();
        }).finally(() => {
            if (withPending) {
                this.applyPendingStyle();
            }
            this.dispatch('update-count');
        });
    }

    applySelectedStyle() {
        if (this.hasSelectedClass) {
            this.blockTarget.classList.toggle(...this.selectedClasses);
        }
    }

    applyPendingStyle() {
        this.disabledValue = !this.disabledValue;
        if (this.disabledValue) {
            this.pendingTarget.classList.remove('tw-hidden');
            this.checkboxTarget.disabled = true;
        } else {
            this.pendingTarget.classList.add('tw-hidden');
            this.checkboxTarget.disabled = false;
        }
    }

    updateDataLayerEventLab() {
        if (!this.hasDisabledBtnTarget) {
            return;
        }

        if (this.disabledValue) {
            this.disabledBtnTarget.setAttribute('data-layer-event-lab', `Restaurer item - ${this.labelValue}`);
        } else {
            this.disabledBtnTarget.setAttribute('data-layer-event-lab', `Supprimer item - ${this.labelValue}`);
        }
    }
}
