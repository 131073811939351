const formErrors = {
    handleErrors(errors) {
        let elements = document.querySelectorAll('.sso-on-error');
        elements.forEach((element) => {
            element.classList.remove('sso-on-error');
        });

        elements = document.querySelectorAll('.sso-error-container');
        elements.forEach((element) => {
            element.innerHTML = '';
        });

        errors.forEach((error) => {
            if (document.querySelector(`#${error.field}`)) {
                document.querySelector(`#${error.field}`).classList.add('sso-on-error');
            }

            document.querySelector(`#${error.field}-error`).innerHTML = error.message;
        });
    },
};

export default formErrors;
