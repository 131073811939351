import domains from './domains';

// Using DropDown element to suggest static list of domains
const emailDomainSuggester = {
    suggestionsListName: 'data-email-domain-suggester',
    init() {
        document.querySelectorAll(`input[${this.suggestionsListName}]`).forEach((emailInput) => {
            if (this.isInitialized(emailInput)) {
                return;
            }

            this.initSuggestionList(emailInput);
            this.addEventListeners(emailInput);

            emailInput.setAttribute(this.suggestionsListName, 'initialized');
        });
    },
    isInitialized(emailInput) {
        return emailInput.getAttribute(this.suggestionsListName) === 'initialized';
    },
    initSuggestionList(emailInput) {
        const emailInputId = emailInput.getAttribute('id');

        if (!emailInputId) {
            return;
        }

        // create empty suggestionsList
        const suggestionsList = document.createElement('datalist');
        suggestionsList.id = `email_options_${emailInput.getAttribute('id')}`;
        emailInput.after(suggestionsList);

        // correlate to input
        emailInput.setAttribute('list', suggestionsList.id);
    },
    addEventListeners(emailInput) {
        let keypress = false;

        emailInput.addEventListener('focusout', () => {
            keypress = false;
        });
        emailInput.addEventListener('keydown', (e) => {
            if (e.key) {
                keypress = true;
            }
        });
        emailInput.addEventListener('input', (e) => {
            const { value } = e.target;

            if (keypress === false) {
                return;
            }

            const suggestionList = document.querySelector(`#email_options_${emailInput.getAttribute('id')}`);
            suggestionList.innerHTML = '';

            // email has @
            if (value.includes('@')) {
                const [prefix, domain] = value.split('@');

                if (domain.length >= 1) {
                    this.addSuggestions(suggestionList, prefix, domain);
                }
            }

            keypress = false;
        });
    },
    addSuggestions(suggestionList, value, requestedDomain) {
        // loop over all the domains in our array
        domains.forEach((domain) => {
            if (!domain.includes(requestedDomain)) {
                return;
            }

            const option = document.createElement('option');
            option.value = `${value}@${domain}`;
            option.text = `${value}@${domain}`;
            suggestionList.appendChild(option);
        });
    },
};

export default emailDomainSuggester;
