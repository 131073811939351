import flatpickr from 'flatpickr';
import { French } from 'flatpickr/dist/l10n/fr';

const datePickerComponent = {
    datePickers: document.querySelectorAll('[data-component=datePickerComponent]'),

    init() {
        if (this.datePickers.length === 0) {
            return false;
        }

        this.displayDatePicker();

        return true;
    },
    displayDatePicker() {
        this.datePickers.forEach((datePicker) => {
            const dataMinDate = datePicker.hasAttribute('data-min-date') ? datePicker.getAttribute('data-min-date') : null;
            const dataMaxDate = datePicker.hasAttribute('data-max-date') ? datePicker.getAttribute('data-max-date') : null;

            flatpickr(datePicker, {
                dateFormat: 'd/m/Y',
                minDate: dataMinDate,
                maxDate: dataMaxDate,
                locale: French,
            });
        });
    },
};

export default datePickerComponent.init();
