import Modal from '../modal-controller';

export default class extends Modal {
    static values = {
        closeOnConfirm: { type: Boolean, default: true },
        closeOnCancel: { type: Boolean, default: true },
    };

    confirm() {
        this.dispatch('confirm');

        if (this.closeOnConfirmValue) {
            this.close();
        }
    }

    cancel() {
        this.dispatch('cancel');

        if (this.closeOnCancelValue) {
            this.close();
        }
    }
}
