import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['filter'];

    static outlets = ['counter', 'clt--form-filter-options-counter'];

    connect() {
        this.update();
    }

    update() {
        this.counterOutlets.forEach((counterOutlet) => {
            counterOutlet.setCounter(this.calculateTotalSelectedFilters());
        });
    }

    reset() {
        this.counterOutlets.forEach((counterOutlet) => {
            counterOutlet.reset();
        });

        this.cltFormFilterOptionsCounterOutlets.forEach((optionsCounterOutlet) => {
            optionsCounterOutlet.reset();
        });
    }

    calculateTotalSelectedFilters() {
        return this.filterTargets.reduce((count, filter) => {
            let selectCount = 0;
            const criteriaSetInput = this.element.querySelector('#filters_criteriaSet');

            if (criteriaSetInput?.[criteriaSetInput.selectedIndex].text != 'Indifférent') {
                selectCount = filter.querySelectorAll('option:checked').length;
            }

            const checkboxesCount = filter.querySelectorAll('input[type="checkbox"]:checked').length;
            return count + checkboxesCount + selectCount;
        }, 0);
    }
}
