const footerMenu = {
    menu: document.querySelector('[data-menu-footer]'),
    listButtons: document.querySelectorAll('[data-menu-mobile-footer-button]'),

    init() {
        if (this.menu !== null && this.listButtons.length) {
            this.addMenu();
        }
    },
    addMenu() {
        Array.from(this.listButtons).map((section) => {
            const button = section.querySelector('button');
            const listLinks = section.querySelector('ul');

            if (button === null || listLinks.length === 0) {
                return;
            }

            button.addEventListener('click', () => {
                const icon = button.querySelector('span');
                listLinks.classList.toggle('tw-hidden');
                icon.classList.toggle('tw-rotate-0');
                icon.classList.toggle('tw-rotate-180');
            });
        });
    },
};

export default footerMenu.init();
