export default class RankingRenderer {
    constructor(dataLayerTemplate) {
        this.dataLayerTemplate = dataLayerTemplate;
    }

    render(recommendation) {
        return `
<a
    href="${recommendation.tracking_url}"
    class="tw-flex-grow tw-flex tw-flex-col tw-bg-white tw hover:tw-shadow-lg focus:tw-shadow-lg
        tw-transition tw-ease-in-out tw-duration-200 hover:tw-transition hover:tw-ease-in-out hover:tw-duration-200
        focus:tw-transition focus:tw-ease-in-out tw-duration-200 tw-relative tw-w-full tw-rounded-large"
    data-layer-event="${this.dataLayerTemplate.event}"
    data-layer-event-cat="${this.dataLayerTemplate.cat}"
    data-layer-event-act="${this.dataLayerTemplate.act}"
    data-layer-event-lab="${this.dataLayerTemplate.lab.replace('{title}', recommendation.title)}"
>
    <div class="tw-p-4 sm:tw-p-6 tw-bg-white tw-rounded-large tw-border tw-border-solid tw-border-gray-400">
        <h3 class="tw-text-xlg sm:tw-text-2xl tw-font-heading tw-flex tw-items-center">
            ${recommendation.title}
            <i class="fal fa-arrow-up-right-from-square tw-ml-auto tw-pl-2 tw-text-primary"></i>
        </h3>
    </div>
</a>
`;
    }
}
