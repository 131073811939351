import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['jobPanel', 'formationPanel', 'otherPanel', 'mainPanel'];

    toggleJob() {
        this.jobPanelTarget.classList.toggle('tw-hidden');
        this.mainPanelTarget.classList.toggle('tw-hidden');
    }

    toggleFormation() {
        this.formationPanelTarget.classList.toggle('tw-hidden');
        this.mainPanelTarget.classList.toggle('tw-hidden');
    }

    toggleOther() {
        this.otherPanelTarget.classList.toggle('tw-hidden');
        this.mainPanelTarget.classList.toggle('tw-hidden');
    }
}
