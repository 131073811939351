import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        // Hidden input
        'selected',
        // Range inputs
        'minInput',
        'maxInput',
        // Tooltips
        'minTooltip',
        'maxTooltip',
        // Outputs of values
        'minOutput',
        'maxOutput',
    ];

    static values = {
        choices: Array,
    };

    initialize() {
        this.minInputTarget.value = this.minChoiceIndex;
        this.maxInputTarget.value = this.maxChoiceIndex;

        this.fillSlider();
        this.fillOutputs();
        this.setToggleAccessible();

        this.positionTooltip(this.minInputTarget, this.minTooltipTarget);
        this.positionTooltip(this.maxInputTarget, this.maxTooltipTarget);
    }

    update() {
        this.normalizeValues();

        this.fillSlider();
        this.fillOutputs();

        this.setToggleAccessible();

        this.positionTooltip(this.minInputTarget, this.minTooltipTarget);
        this.positionTooltip(this.maxInputTarget, this.maxTooltipTarget);

        this.dispatch('updated');
    }

    normalizeValues() {
        if (this.from <= this.to) {
            this.minInputTarget.value = this.from;
            this.maxInputTarget.value = this.to;
        } else {
            this.minInputTarget.value = this.to;
            this.maxInputTarget.value = this.from;
        }

        if (this.from === this.to) {
            // Handle single value by slug instead of its value.
            this.selectedTarget.value = this.choicesValue[this.from]?.slug;
        } else if (this.from === 0 && this.to === this.choicesValue.length - 1) {
            // Having a range from 0 to the last element is the same as having no range.
            this.selectedTarget.value = '';
        } else {
            this.selectedTarget.value = this.choicesValue.slice(this.from, this.to + 1).map((choice) => choice.selectOptionValue).join(' ');
        }
    }

    fillSlider() {
        const rangeDistance = this.maxInputTarget.max - this.maxInputTarget.min;
        const fromPosition = this.minInputTarget.value - this.maxInputTarget.min;
        const toPosition = this.maxInputTarget.value - this.maxInputTarget.min;

        if (fromPosition === 0 && toPosition === rangeDistance) {
            this.minInputTarget.style.setProperty('--slider-thumb-color', 'var(--slider-thumb-inactive-color)');
            this.maxInputTarget.style.setProperty('--slider-thumb-color', 'var(--slider-thumb-inactive-color)');
            this.maxInputTarget.style.background = 'var(--slider-track-color)';

            return;
        }

        this.minInputTarget.style.removeProperty('--slider-thumb-color');
        this.maxInputTarget.style.removeProperty('--slider-thumb-color');
        this.maxInputTarget.style.background = `linear-gradient(
          to right,
          var(--slider-track-color) 0%,
          var(--slider-track-color) ${(fromPosition / rangeDistance) * 100}%,
          var(--slider-range-color) ${((fromPosition / rangeDistance)) * 100}%,
          var(--slider-range-color) ${(toPosition / rangeDistance) * 100}%, 
          var(--slider-track-color) ${(toPosition / rangeDistance) * 100}%, 
          var(--slider-track-color) 100%)`;
    }

    /**
     * Ensure that the max input is always accessible (on top of the min input).
     */
    setToggleAccessible() {
        if (Number(this.maxInputTarget.value) <= Number(this.minInputTarget.min)) {
            this.maxInputTarget.style.zIndex = 2;
        } else {
            this.maxInputTarget.style.zIndex = 0;
        }
    }

    fillOutputs() {
        if (this.hasMinOutputTarget) {
            this.minOutputTargets.forEach((output) => {
                output.textContent = this.choicesValue[this.minChoiceIndex]?.selectOptionLabel;
            });
        }

        if (this.hasMaxOutputTarget) {
            this.maxOutputTargets.forEach((output) => {
                output.textContent = this.choicesValue[this.maxChoiceIndex]?.selectOptionLabel;
            });
        }
    }

    positionTooltip(range, bubble) {
        const val = range.value;
        const min = range.min ? range.min : 0;
        const max = range.max ? range.max : 100;
        const newVal = Number(((val - min) * 100) / (max - min));

        // Thumb is 24px wide (8px width + 8px border * 2). Don't kwow why we need to add 2px to the thumbSize.
        const thumbSize = 26;
        bubble.style.left = `calc(${newVal}% + ${thumbSize / 2}px - ${thumbSize}px * ${newVal / 100})`;
    }

    get from() {
        return parseInt(this.minInputTarget.value, 10);
    }

    get to() {
        return parseInt(this.maxInputTarget.value, 10);
    }

    get selectedValues() {
        const values = this.selectedTarget.value.split(' ');

        if (values.length === 1) {
            return values;
        }

        return values.map((value) => parseInt(value, 10));
    }

    get selectedItems() {
        const selectedValues = this.selectedValues;

        return this.choicesValue.filter((choice) => {
            if (selectedValues.length === 1) {
                return choice.slug === selectedValues[0];
            }

            return selectedValues.includes(choice.selectOptionValue);
        });
    }

    get minChoiceIndex() {
        if (this.selectedItems.length === 0) {
            return 0;
        }

        return this.choicesValue.findIndex((choice) => choice.selectOptionValue === this.selectedItems[0].selectOptionValue);
    }

    get maxChoiceIndex() {
        if (this.selectedItems.length === 0) {
            return this.choicesValue.length - 1;
        }

        const lastItem = this.selectedItems[this.selectedItems.length - 1];

        return this.choicesValue.findIndex((choice) => choice.selectOptionValue === lastItem.selectOptionValue);
    }
}
