import { Controller } from '@hotwired/stimulus';

import { transition } from '../utils/transition';

export default class extends Controller {
    static targets = ['collapsable', 'trigger', 'hint', 'label', 'overflow', 'labelCollapsed', 'labelExpended'];

    static classes = ['hintCollapsed', 'hintExpanded', 'labelCollapsed', 'labelExpanded', 'triggerCollapsed', 'triggerExpanded'];

    static values = {
        open: Boolean,
    };

    connect() {
        window.setTimeout(() => {
            const hash = window.location.hash;
            if (hash !== '' && this.element.querySelector(hash) !== null) {
                this.show();
                this.element.scrollIntoView();
            }
        }, 100);
    }

    openValueChanged() {
        if (this.hasOverflowTarget) {
            this.overflowTarget.classList.add('tw-overflow-hidden');
        }

        transition(this.collapsableTarget, this.openValue).then(() => {
            this.dispatch('toggle', { detail: { open: this.openValue } });

            if (this.hasOverflowTarget) {
                this.overflowTarget.classList.remove('tw-overflow-hidden');
            }
        });

        if (this.hasHintTarget) {
            this.hintTargets.forEach((hint) => {
                if (this.hasHintCollapsedClass) {
                    // eslint-disable-next-line no-unused-expressions
                    this.openValue ? hint.classList.remove(...this.hintCollapsedClasses) : hint.classList.add(...this.hintCollapsedClasses);
                }
                if (this.hasHintExpandedClass) {
                    // eslint-disable-next-line no-unused-expressions
                    this.openValue ? hint.classList.add(...this.hintExpandedClasses) : hint.classList.remove(...this.hintExpandedClasses);
                }
            });
        }

        if (this.hasLabelCollapsedTarget) {
            // eslint-disable-next-line no-unused-expressions
            this.openValue ? this.labelCollapsedTarget.classList.add('tw-hidden') : this.labelCollapsedTarget.classList.remove('tw-hidden');
        }

        if (this.hasLabelExpendedTarget) {
            // eslint-disable-next-line no-unused-expressions
            this.openValue ? this.labelExpendedTarget.classList.remove('tw-hidden') : this.labelExpendedTarget.classList.add('tw-hidden');
        }

        if (this.hasLabelTarget) {
            this.labelTargets.forEach((label) => {
                if (this.hasLabelCollapsedClass) {
                    // eslint-disable-next-line max-len,no-unused-expressions
                    this.openValue ? label.classList.remove(...this.labelCollapsedClasses) : label.classList.add(...this.labelCollapsedClasses);
                }

                if (this.hasLabelExpandedClass) {
                    // eslint-disable-next-line max-len,no-unused-expressions
                    this.openValue ? label.classList.add(...this.labelExpandedClasses) : label.classList.remove(...this.labelExpandedClasses);
                }
            });
        }

        if (this.hasTriggerTarget) {
            this.triggerTargets.forEach((trigger) => {
                if (this.hasTriggerCollapsedClass) {
                    // eslint-disable-next-line no-unused-expressions
                    this.openValue
                        ? trigger.classList.remove(...this.triggerCollapsedClasses)
                        : trigger.classList.add(...this.triggerCollapsedClasses);
                }
                if (this.hasTriggerExpandedClass) {
                    // eslint-disable-next-line no-unused-expressions
                    this.openValue
                        ? trigger.classList.add(...this.triggerExpandedClasses)
                        : trigger.classList.remove(...this.triggerExpandedClasses);
                }
            });
        }
    }

    toggle() {
        this.openValue = !this.openValue;
    }

    show() {
        this.openValue = true;
    }

    hide() {
        this.openValue = false;
    }

    checkOutsideAndHide(event) {
        if (!this.triggerTarget.contains(event.target) && !this.collapsableTarget.contains(event.target)) {
            this.hide();
        }
    }
}
