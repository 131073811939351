import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['checkbox'];

    connect() {
        this.syncDataLayerEvents();
    }

    syncDataLayerEvents() {
        this.checkboxTargets.forEach((checkbox) => {
            if (checkbox.checked) {
                checkbox.setAttribute('data-layer-event-act', 'optout');
            } else {
                checkbox.setAttribute('data-layer-event-act', 'optin');
            }
        });
    }
}
