import formFields from './form/formFields';
import ssoFirstStep from './form/ssoFirstStep';
import ssoQualifyStep from './form/ssoQualifyStep';
import user from './local_storage/user';

const sso = {
    registerButton: null,
    ssoFirstStepForm: null,
    ssoQualifyStepForm: null,
    init() {
        this.registerButton = document.querySelector('#sso-register-button');
        this.ssoFirstStepForm = document.querySelector('#sso-first-step-form');
        this.ssoQualifyStepForm = document.querySelector('#sso-qualify-step-form');

        const queryParams = window.location.search ? new URLSearchParams(window.location.search) : null;

        if (queryParams && queryParams.has('accountUpdate') && queryParams.get('accountUpdate') === 'success') {
            import(/* webpackChunkName: "toaster" */ '../toaster/toaster').then((component) => {
                component.default.display('success', 'Votre demande a bien été prise en compte.');
            });
        }

        // Init connexion and save user in local storage
        user.init();

        // Set event to login password input
        formFields.handlePassword();

        if (this.registerButton) {
            this.registerButton.addEventListener('click', () => {
                ssoFirstStep.buildFirstStepForm();
            });
        }

        if (this.ssoQualifyStepForm) {
            ssoQualifyStep.buildQualifyStepForm();
        }
    },
};

export default sso;
