import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static outlets = ['sso--requalify-profile'];

    static targets = [
        'modal',
        'form',
        'profileSelector',
    ];

    initialize() {
        this.selectComponent = null;
    }

    ssoRequalifyProfileOutletConnected(outlet) {
        this.toggleProfile(outlet);
    }

    load() {
        import(/* webpackChunkName: "axios" */ 'axios').then((axios) => {
            axios.default.get(
                '/compte/mon-profil/changer-niveau-etudes.html',
                { headers: { 'X-Requested-With': 'XMLHttpRequest' } },
            ).then((response) => {
                this.replaceModalContent(response.data);
            }).finally(() => {
                this.dispatch('loaded');
            });
        });
    }

    replaceModalContent(content) {
        this.modalTarget.innerHTML = content;

        this.initializeSelect();
    }

    initializeSelect() {
        if (!this.selectComponent) {
            import(
                /* webpackChunkName: "selectComponent" */ '../../components/select/selectComponent/selectComponent'
            ).then((component) => {
                this.selectComponent = component.selectComponent;
            });

            return;
        }

        this.selectComponent.reload(this.element);
    }

    keepCurrentProfile(event) {
        event.preventDefault();

        this.dispatch('form-loading');

        import(/* webpackChunkName: "axios" */ 'axios').then((axios) => {
            axios.default.post(
                '/compte/mon-profil/conserver-niveau-etudes.html',
                {},
                {
                    headers: { 'X-Requested-With': 'XMLHttpRequest' },
                    params: {
                        referer: window.location.href,
                    },
                },
            ).then((response) => {
                const content = response.data;

                if (typeof content.redirect !== 'undefined' && content.redirect !== null) {
                    window.location.href = content.redirect;
                } else {
                    this.replaceModalContent(content);
                    this.dispatch('form-submitted');
                }
            }).catch(() => {
                this.dispatch('form-submitted');
            });
        });
    }

    toggleProfiles() {
        this.ssoRequalifyProfileOutlets.forEach((requalifyProfile) => this.toggleProfile(requalifyProfile));
    }

    toggleProfile(requalifyProfile) {
        if (requalifyProfile.idValue !== this.profileSelectorTarget.value) {
            requalifyProfile.element.classList.add('tw-hidden');
        } else {
            requalifyProfile.element.classList.remove('tw-hidden');
        }
    }

    submitForm(event) {
        event.preventDefault();

        this.dispatch('form-loading');

        import(/* webpackChunkName: "axios" */ 'axios').then((axios) => {
            axios.default.post(
                event.target.getAttribute('action'),
                new FormData(event.target),
                {
                    headers: { 'X-Requested-With': 'XMLHttpRequest' },
                    params: {
                        referer: window.location.href,
                    },
                },
            ).then((response) => {
                const content = response.data;

                if (typeof content.redirect !== 'undefined' && content.redirect !== null) {
                    window.location.href = content.redirect;
                } else {
                    this.replaceModalContent(content);
                    this.dispatch('form-submitted');
                }
            }).catch(() => {
                this.dispatch('form-submitted');
            });
        });
    }
}
