import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['autocomplete'];

    static values = {
        country: { type: String, default: 'fr' },
    };

    countryValueChanged(currentCountry, oldCountry) {
        if (currentCountry === oldCountry) {
            return;
        }

        if (!this.isInFrance()) {
            this.element.classList.add('tw-hidden');
            this.autocompleteTarget.value = '';
            this.autocompleteTarget.removeAttribute('data-autocomplete-no-default-value');
            this.autocompleteTarget.dispatchEvent(new KeyboardEvent('keyup'));
        } else {
            this.autocompleteTarget.setAttribute('data-autocomplete-no-default-value', 'true');
            this.element.classList.remove('tw-hidden');
        }
    }

    isInFrance() {
        return [
            // France
            'fr',
            // Guadeloupe
            'gp',
            // Martinique
            'mq',
            // Guyane
            'gf',
            // Réunion
            're',
            // Mayotte
            'yt',
            // Saint-Martin
            'mf',
            // Saint-Barthélemy
            'bl',
            // Saint-Pierre-et-Miquelon
            'pm',
            // Wallis-et-Futuna
            'wf',
            // Polynésie française
            'pf',
            // Nouvelle-Calédonie
            'nc',
        ].includes(this.countryValue);
    }
}
