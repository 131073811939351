const ssoBreadcrumb = {
    generateBreadcrumb(number = 5) {
        let dom = '<div class="tw-main-container">'
            + '<ul class="tw-flex tw-justify-center">';

        for (let i = 1; i <= number; i++) {
            // eslint-disable-next-line max-len
            dom += `<li id="sso-breadcrumb-element-${i}" class="tw-flex tw-justify-center tw-items-center tw-w-12 tw-h-12 tw-mx-1 sm:tw-mx-2 `
                + `tw-rounded-full tw-font-heading tw-text-lg tw-leading-4 tw-border-solid tw-border tw-border-gray-400">${
                    i}</li>`;
        }

        dom += '</li></ul></div>';

        return dom;
    },
    updateBreadcrumb(number = 5, active = 1) {
        document.querySelector('#sso-breadcrumb').innerHTML = this.generateBreadcrumb(number);

        for (let i = 1; i <= number; i++) {
            const breadcrumbElement = document.querySelector(`#sso-breadcrumb-element-${i}`);

            if (i === active) {
                breadcrumbElement.classList.add('tw-text-white');
                breadcrumbElement.classList.add('tw-bg-primary');
                breadcrumbElement.classList.add('tw-border-primary');
            } else if (i < active) {
                breadcrumbElement.classList.remove('tw-text-white');
                breadcrumbElement.classList.remove('tw-bg-primary');
                breadcrumbElement.classList.remove('tw-border-primary');
                breadcrumbElement.classList.add('tw-bg-gray-100');
                breadcrumbElement.classList.add('tw-border-gray-400');
            } else {
                breadcrumbElement.classList.remove('tw-border-primary');
                breadcrumbElement.classList.remove('tw-bg-primary');
                breadcrumbElement.classList.remove('tw-text-white');
                breadcrumbElement.classList.add('tw-border-gray-400');
            }
        }
    },
};

export default ssoBreadcrumb;
