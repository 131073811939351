import formFields from './formFields';

const specialitiesAndStream = {
    showStreamSup: ['bac-1', 'bac-2', 'bac-3', 'bac-4', 'bac-5-et-au-dela'],
    showStreamTechno: ['premiere-technologique', 'terminale-technologique'],
    showSpecialities: ['premiere', 'terminale'],
    containerStudySpecialities: null,
    containerStudyStream: null,
    studyLevelsSlugs: null,
    studyLevelCheckbox: null,
    suffixChildren: null,
    isParent: null,
    handle(additionalData, streamFieldConf = [], isParentStudyLevels = false) {
        this.isParent = isParentStudyLevels;

        this.suffixChildren = '';
        if (this.isParent) {
            this.suffixChildren = '_children';
        }

        this.containerStudySpecialities = document.querySelector(`#container-study_specialities${this.suffixChildren}`);
        this.containerStudyStream = document.querySelector(`#container-study_stream${this.suffixChildren}`);
        this.studyLevelCheckbox = document.getElementsByName(`study_level${this.isParent ? `${this.suffixChildren}[]` : ''}`);

        const studySpecialitiesLabel = document.querySelector(`#study_specialities${this.suffixChildren}-label`);
        const studyStreamLabel = document.querySelector(`#study_stream${this.suffixChildren}-label`);

        this.hideElement(this.containerStudySpecialities, studySpecialitiesLabel);
        this.hideElement(this.containerStudyStream, studyStreamLabel, true);

        if (this.studyLevelCheckbox.length === 0) {
            return;
        }

        if (typeof additionalData.studyLevelsSlugs === 'undefined') {
            return;
        }

        this.studyLevelsSlugs = additionalData.studyLevelsSlugs;

        let studyLevelsChecked = [];
        this.studyLevelCheckbox.forEach((checkbox) => {
            checkbox.addEventListener('click', (check) => {
                // Add/Remove pour les parents, il faut donc un tableau de niveau d'etude
                // avec ajout suppression au changement
                // Radio button pour les autres profils, on recupere juste la valeur
                if (this.isParent) {
                    if (studyLevelsChecked.includes(check.target.value)) {
                        for (let i = 0; i < studyLevelsChecked.length; i++) {
                            if (studyLevelsChecked[i] === check.target.value) {
                                studyLevelsChecked.splice(i, 1);
                            }
                        }
                    } else {
                        studyLevelsChecked.push(check.target.value);
                    }
                } else {
                    studyLevelsChecked = [check.target.value];
                }

                let showStream = false;
                let showSpecialities = false;
                const selectedStudyLevelsSlugs = [];
                this.studyLevelsSlugs.forEach((studyLevel) => {
                    if (!studyLevelsChecked.includes(studyLevel.id)) {
                        return;
                    }

                    selectedStudyLevelsSlugs.push(studyLevel.slug);

                    if (this.showSpecialities.includes(studyLevel.slug)) {
                        showSpecialities = true;
                    }

                    if (this.showStreamSup.includes(studyLevel.slug) || this.showStreamTechno.includes(studyLevel.slug)) {
                        showStream = true;
                    }
                });

                if (showStream) {
                    if (Object.keys(streamFieldConf)) {
                        this.filterStudyStream(selectedStudyLevelsSlugs, additionalData, streamFieldConf);
                    }

                    this.showElement(this.containerStudyStream, studyStreamLabel, true);
                } else {
                    this.hideElement(this.containerStudyStream, studyStreamLabel, true);
                    this.resetValue(true, false);
                }

                if (showSpecialities) {
                    this.showElement(this.containerStudySpecialities, studySpecialitiesLabel);
                } else {
                    this.hideElement(this.containerStudySpecialities, studySpecialitiesLabel);
                    this.resetValue(false, true);
                }
            });
        });
    },
    resetValue(onlyStream = true, onlySpecialities = true) {
        if (onlySpecialities) {
            document.getElementsByName(`study_specialities${this.suffixChildren}[]`).forEach((item) => {
                item.checked = false;
            });
        }

        if (onlyStream) {
            document.getElementsByName(`study_stream${this.isParent ? `${this.suffixChildren}[]` : ''}`).forEach((item) => {
                item.checked = false;
            });
        }

        const specialitiesErrors = document.querySelector(`#study_specialities${this.suffixChildren}-error`);
        const studyLevelErrors = document.querySelector(`#study_level${this.suffixChildren}-error`);
        const streamErrors = document.querySelector(`#study_stream${this.suffixChildren}-error`);

        if (specialitiesErrors !== null) {
            specialitiesErrors.innerHTML = '';
        }

        if (studyLevelErrors !== null) {
            studyLevelErrors.innerHTML = '';
        }

        if (streamErrors !== null) {
            streamErrors.innerHTML = '';
        }
    },
    showElement(element, label, hasFlex = false) {
        if (element !== null) {
            if (hasFlex) {
                element.classList.add('sm:tw-flex');
            }

            element.classList.remove('tw-hidden');
        }

        if (label !== null) {
            label.classList.remove('tw-hidden');
        }
    },
    hideElement(element, label, hasFlex = false) {
        if (element !== null) {
            if (hasFlex) {
                element.classList.remove('sm:tw-flex');
            }

            element.classList.add('tw-hidden');
        }

        if (label !== null) {
            label.classList.add('tw-hidden');
        }
    },
    canSubmit(formData) {
        let withoutError = true;
        // No study level checkboxes -> No validation on specialities
        if (this.studyLevelCheckbox.length === 0) {
            return withoutError;
        }

        let hasContainerStudySpecialities = false;
        let hasContainerStudyStream = false;

        if (this.containerStudySpecialities !== null) {
            hasContainerStudySpecialities = !this.containerStudySpecialities.classList.contains('tw-hidden');
        }

        if (this.containerStudyStream !== null) {
            hasContainerStudyStream = !this.containerStudyStream.classList.contains('tw-hidden');
        }

        if (hasContainerStudyStream) {
            document.querySelector(`#study_stream${this.suffixChildren}-error`).innerHTML = '';
        }

        if (hasContainerStudySpecialities) {
            document.querySelector(`#study_specialities${this.suffixChildren}-error`).innerHTML = '';
        }

        if (hasContainerStudyStream
            && (formData.get(`study_stream${this.isParent ? `${this.suffixChildren}[]` : ''}`) === ''
            || formData.get(`study_stream${this.isParent ? `${this.suffixChildren}[]` : ''}`) === null)
        ) {
            document.querySelector(`#study_stream${this.suffixChildren}-error`).innerHTML = 'Cette valeur est requise';

            withoutError = false;
        }

        // eslint-disable-next-line max-len
        if (hasContainerStudySpecialities && (formData.get(`study_specialities${this.suffixChildren}[]`) === '' || formData.get(`study_specialities${this.suffixChildren}[]`) === null)) {
            document.querySelector(`#study_specialities${this.suffixChildren}-error`).innerHTML = 'Cette valeur est requise';

            withoutError = false;
        }

        const studyLevelSelected = formData.get(`study_level${this.isParent ? `${this.suffixChildren}[]` : ''}`);
        if (studyLevelSelected === null) {
            withoutError = false;
        }

        this.studyLevelsSlugs.forEach((item) => {
            if (Array.isArray(studyLevelSelected) && !studyLevelSelected.include(item.id)) {
                return;
            } if (studyLevelSelected !== item.id) {
                return;
            }

            if (this.isParent && item.slug === 'premiere' && formData.getAll(`study_specialities${this.suffixChildren}[]`).length < 3) {
                // eslint-disable-next-line max-len
                document.querySelector(`#study_specialities${this.suffixChildren}-error`).innerHTML = 'Au moins 3 spécialités doivent être saisies';
                withoutError = false;

                return;
            }

            if (this.isParent && item.slug === 'terminale' && formData.getAll(`study_specialities${this.suffixChildren}[]`).length < 2) {
                // eslint-disable-next-line max-len
                document.querySelector(`#study_specialities${this.suffixChildren}-error`).innerHTML = 'Au moins 2 spécialités doivent être saisies';
                withoutError = false;

                return;
            }

            if (this.isParent && formData.getAll(`study_level${this.suffixChildren}[]`).length > 3) {
                // eslint-disable-next-line max-len
                document.querySelector(`#study_level${this.suffixChildren}-error`).innerHTML = 'Il est possible de choisir au maximum trois niveaux d\'étude pour vos enfants.';
                withoutError = false;
            }

            // 3 Specialités en premiere, 2 en terminale
            if (!this.isParent && item.slug === 'premiere' && formData.getAll('study_specialities[]').length !== 3) {
                document.querySelector('#study_specialities-error').innerHTML = 'Si tu es en première, tu dois spécifier 3 spécialités';
                withoutError = false;
            }

            if (!this.isParent && item.slug === 'terminale' && formData.getAll('study_specialities[]').length !== 2) {
                document.querySelector('#study_specialities-error').innerHTML = 'Si tu es en terminale, tu dois spécifier 2 spécialités';
                withoutError = false;
            }
        });

        return withoutError;
    },
    filterStudyStream(selectedStudyLevelsSlugs, additionalData, streamFieldConf) {
        let type = '';
        selectedStudyLevelsSlugs.forEach((slug) => {
            if (type === 'technologique' && this.showStreamSup.includes(slug)) {
                type = 'both';

                return;
            }

            if (type === 'superieur' && this.showStreamTechno.includes(slug)) {
                type = 'both';

                return;
            }

            if (this.showStreamSup.includes(slug)) {
                type = 'superieur';
            }

            if (this.showStreamTechno.includes(slug)) {
                type = 'technologique';
            }
        });

        const optionFiltered = [];
        additionalData.studyStreamTypes.forEach((item) => {
            if (type === 'both') {
                optionFiltered.push(item);
            } else if (type === item.type) {
                optionFiltered.push(item);
            }
        });

        const newStreamFieldConf = streamFieldConf;
        newStreamFieldConf.options = optionFiltered;

        // eslint-disable-next-line max-len
        document.querySelector(`#container-study_stream${this.suffixChildren}`).outerHTML = formFields.getDom(newStreamFieldConf, false, false);
        this.containerStudyStream = document.querySelector(`#container-study_stream${this.suffixChildren}`);
    },
};

export default specialitiesAndStream;
