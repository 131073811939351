import axios from 'axios';

export default class ApiClient {
    constructor(selligentUserId, widgetIds = []) {
        this.selligentUserId = selligentUserId;
        this.widgetIds = widgetIds;
    }

    getRecommendations() {
        if (!this.selligentUserId) {
            return this.#recommendationsRequest();
        }

        return this.#siteProfileRequest()
            .then((data) => this.#recommendationsRequest({ sid: data.$$profileId, tpid: data.$$thirdPartyId }))
            .catch(() => this.#recommendationsRequest());
    }

    #siteProfileRequest() {
        const requestData = {
            universeId: SELLIGENT_SITE_UNIVERSE_ID,
            customIdentifier: this.selligentUserId,
            isEvent: true,
            isIdentificationRequest: true,
            isTargeting: false,
        };

        return new Promise((resolve, reject) => {
            axios.post('https://site-azp.slgnt.eu/frontend/api/track', requestData, { headers: { Accept: 'application/json;version=1' } })
                .then(({ data }) => {
                    if (data === '' || !('$$profileId' in data) || !('$$thirdParty' in data)) {
                        reject();

                        return;
                    }
                    resolve(data);
                })
                .catch(() => reject());
        });
    }

    #recommendationsRequest(userProfile = {}) {
        const requests = this.widgetIds.map(
            (widgetid) => {
                const requestParams = { ...userProfile, widgetid };

                return axios.get(
                    `https://offer.slgnt.eu/api/${SELLIGENT_RECOMMENDATIONS_CUSTOMER_ID}/r`,
                    {
                        headers: { Accept: 'application/json;version=1' },
                        params: requestParams,
                    },
                ).catch((error) => {
                    // eslint-disable-next-line no-console
                    console.error(error);

                    return { data: [] };
                });
            },
        );

        return Promise
            .all(requests)
            .then((results) => {
                const recommendations = [];

                results.forEach(({ data }) => {
                    if (!Array.isArray(data)) {
                        return;
                    }

                    recommendations.push(...data);
                });

                return recommendations;
            });
    }
}
