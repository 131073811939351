import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['search', 'option', 'reset'];

    connect() {
        if (this.hasSearchTarget) {
            this.searchTarget.addEventListener('input', this.search.bind(this));
        }
    }

    search() {
        const searchValue = this.searchTarget.value.toLowerCase();

        if (searchValue === '') {
            this.reset();

            return;
        }

        this.optionTargets.forEach((option) => {
            const optionValue = option.querySelector('input').getAttribute('data-label').toLowerCase();

            if (optionValue.includes(searchValue)) {
                option.classList.remove('tw-hidden');
            } else {
                option.classList.add('tw-hidden');
            }
        });

        this.resetTarget.classList.remove('tw-hidden');
    }

    reset() {
        this.searchTarget.value = '';

        this.optionTargets.forEach((option) => {
            option.classList.remove('tw-hidden');
        });

        this.resetTarget.classList.add('tw-hidden');
    }
}
