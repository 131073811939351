import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['label', 'counter'];

    static values = {
        counter: { type: Number, default: 0 },
        hiddenAtZero: { type: Boolean, default: false },
        defaultLabel: { type: String, default: '' },
        labelWithFilters: { type: String, default: '' },
    };

    counterValueChanged(currentCounter, oldCounter) {
        if (currentCounter === oldCounter) {
            return;
        }

        if (this.hiddenAtZeroValue) {
            if (currentCounter === 0) {
                this.counterTarget.classList.add('tw-hidden');
            } else {
                this.counterTarget.classList.remove('tw-hidden');
            }
        }

        this.updateLabel(currentCounter);
    }

    updateLabel(currentCounter) {
        const label = currentCounter === 0 ? this.defaultLabelValue : this.labelWithFiltersValue;
        this.labelTarget.innerText = label;
        this.counterTarget.innerText = currentCounter;
    }

    setCounter(counter) {
        this.counterValue = counter;
    }

    reset() {
        this.counterValue = 0;
    }
}
