import { Controller } from '@hotwired/stimulus';

import ApiClient from '../../smart-content/ApiClient';
import RankingRenderer from '../../smart-content/renderer/clt/RankingRenderer';
import ArticleRenderer from '../../smart-content/renderer/edt/ArticleRenderer';
import StudyCardHorizontalRenderer from '../../smart-content/renderer/fet/StudyCardHorizontalRenderer';
import JobCardHorizontalRenderer from '../../smart-content/renderer/fme/JobCardHorizontalRenderer';
import QuizHorizontalRenderer from '../../smart-content/renderer/jcs/QuizHorizontalRenderer';
import TestHorizontalRenderer from '../../smart-content/renderer/jcs/TestHorizontalRenderer';
import RecommendationRenderer from '../../smart-content/renderer/RecommendationRenderer';
import ShowHorizontalRenderer from '../../smart-content/renderer/sal/ShowHorizontalRenderer';

/**
 * Populate "recommendations" target with suggested blocks provided by Smart Content API.
 */
export default class extends Controller {
    static targets = ['wrapper', 'noResults', 'recommendations'];

    static values = {
        widgetIds: Array,
        dataLayerTemplate: Object,
    };

    initialized = false;

    connect() {
        this.boundUpdateView = this.updateView.bind(this);
        document.addEventListener('userSessionUpdated', this.boundUpdateView);

        this.updateView();
    }

    disconnect() {
        document.removeEventListener('userSessionUpdated', this.boundUpdateView);
    }

    updateView() {
        if (this.initialized) {
            return;
        }

        const user = JSON.parse(sessionStorage.getItem('user'));
        if (!user?.content?.connected) {
            this.wrapperTarget.classList.add('tw-hidden');
            this.noResultsTarget.classList.remove('tw-hidden');

            return;
        }

        this.initialized = true;

        new ApiClient(user?.content?.user.mailing_router_id, this.widgetIdsValue)
            .getRecommendations()
            .then((recommendations) => {
                const renderers = {
                    clt_ranking: new RankingRenderer(this.dataLayerTemplateValue),
                    edt_article: new ArticleRenderer(this.dataLayerTemplateValue),
                    fet_study_card: new StudyCardHorizontalRenderer(this.dataLayerTemplateValue),
                    fme_job_card: new JobCardHorizontalRenderer(this.dataLayerTemplateValue),
                    jcs_quiz: new QuizHorizontalRenderer(this.dataLayerTemplateValue),
                    jcs_test: new TestHorizontalRenderer(this.dataLayerTemplateValue),
                    sal_show: new ShowHorizontalRenderer(this.dataLayerTemplateValue),
                };

                const html = new RecommendationRenderer(renderers).renderHtml(recommendations);
                if (html !== '') {
                    this.recommendationsTarget.innerHTML = html;
                    this.wrapperTarget.classList.remove('tw-hidden');
                    this.noResultsTarget.classList.add('tw-hidden');
                } else {
                    this.wrapperTarget.classList.add('tw-hidden');
                    this.noResultsTarget.classList.remove('tw-hidden');
                }
            });
    }
}
