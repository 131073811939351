import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['modal', 'loader'];

    open() {
        this.modalTarget.classList.remove('tw-hidden');
    }

    close() {
        this.modalTarget.classList.add('tw-hidden');
    }

    showLoader() {
        if (this.hasLoaderTarget) {
            this.loaderTarget.classList.remove('tw-hidden');
        }
    }

    hideLoader() {
        if (this.hasLoaderTarget) {
            this.loaderTarget.classList.add('tw-hidden');
        }
    }
}
