export default class DateRenderer {
    render(recommendation) {
        if (recommendation.begin_at.split(' ')[0] === recommendation.end_at.split(' ')[0] || recommendation.end_at === '') {
            return this.#dateContent(recommendation.begin_at);
        }

        return `
            ${this.#dateContent(recommendation.begin_at)}
            <span class="tw-mx-3">au</span>
            ${this.#dateContent(recommendation.end_at)}
        `;
    }

    #dateContent(date) {
        const dateParts = new Date(this.#trimLeadingQuotes(date))
            .toLocaleDateString('fr-FR', { day: 'numeric', month: 'short' })
            .split(' ');

        return `
            <time class="tw-block tw-font-heading tw-text-3xl tw-leading-7 tw-text-center" datetime="${date.split(' ')[0]}">
                ${dateParts[0]}
                <span class="tw-block tw-text-sm tw-leading-normalBis tw-uppercase">${dateParts[1]}</span>
            </time>
        `;
    }

    #trimLeadingQuotes(content) {
        return content.replace(/^"/, '').replace(/"$/, '');
    }
}
