import { Controller } from '@hotwired/stimulus';

/**
 * Check that at least one input is selected to allow form submit
 */
export default class extends Controller {
    static targets = ['selection', 'submit'];

    static classes = ['enabled', 'disabled'];

    connect() {
        this.check();
    }

    check() {
        const allowSubmit = this.selectionTargets.some((selection) => selection.checked);

        if (allowSubmit) {
            this.submitTarget.removeAttribute('disabled');
            this.submitTarget.classList.remove(...this.disabledClasses);
            this.submitTarget.classList.add(...this.enabledClasses);
        } else {
            this.submitTarget.disabled = true;
            this.submitTarget.classList.add(...this.disabledClasses);
            this.submitTarget.classList.remove(...this.enabledClasses);
        }
    }
}
