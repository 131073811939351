import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['filter', 'criteriaSet', 'preview', 'additionalFilter'];

    showAdditionalFilter({ params: { id } }) {
        this.additionalFilterTargets.forEach((filter) => {
            if (filter.querySelector(`#${id}`)) {
                filter.classList.remove('tw-hidden');
            }
        });

        document.querySelector('#ranking-filter aside').scrollTo({ top: 0, behavior: 'smooth' });
    }

    hideAdditionalFilter({ params: { id } }) {
        this.additionalFilterTargets.forEach((filter) => {
            if (filter.querySelector(`#${id}`)) {
                filter.classList.add('tw-hidden');
            }
        });
    }

    submit() {
        const hasActiveFilters = [...this.filterTargets].some((filter) => filter.value !== '');

        let criteriaSetUrl = window.location.href;

        if (this.hasCriteriaSetTarget) {
            criteriaSetUrl = this.element.querySelector(`[name="${this.criteriaSetTarget.name}"]`).value;
        }

        if (!hasActiveFilters && !this.hasPreviewTarget) {
            window.location = criteriaSetUrl;
            return;
        }

        this.filterTargets.forEach((filter) => {
            if (filter.value === '') {
                // Avoid submitting the filter as an empty GET parameter.
                filter.disabled = 'disabled';
            }
        });

        if (this.hasCriteriaSetTarget) {
            this.criteriaSetTarget.disabled = 'disabled';
        }

        this.element.method = 'POST';
        this.element.action = criteriaSetUrl;

        this.element.submit();
    }
}
