/* eslint-disable no-unused-expressions */
const component = {
    init() {
        const passwordTogglers = document.querySelectorAll('[data-password-toggler]');

        passwordTogglers.forEach((container) => {
            const target = container.dataset?.passwordTogglerTarget;
            if (!target) {
                return;
            }

            const targetInput = document.querySelector(`#${target}`);
            if (!targetInput) {
                return;
            }

            const hideAction = container.querySelector('[data-password-toggler-action="hide"]');
            const showAction = container.querySelector('[data-password-toggler-action="show"]');

            hideAction?.addEventListener('click', () => {
                hideAction.classList.add('tw-hidden');
                showAction.classList.remove('tw-hidden');
                targetInput.type = 'text';
            });

            showAction?.addEventListener('click', () => {
                hideAction.classList.remove('tw-hidden');
                showAction.classList.add('tw-hidden');
                targetInput.type = 'password';
            });
        });
    },
};

export default component;
