import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['notification', 'notificationBadge'];

    loadNotifications() {
        const user = JSON.parse(sessionStorage.getItem('user'));
        if (!user?.content?.connected) {
            this.notificationTarget.classList.add('tw-hidden');
            this.notificationBadgeTarget.classList.add('tw-hidden');

            return;
        }

        this.notificationTarget.classList.remove('tw-hidden');
    }

    showNotificationBadge() {
        if (this.hasNotificationBadgeTarget) {
            this.notificationBadgeTarget.classList.remove('tw-hidden');
        }
    }

    hideNotificationBadge() {
        if (this.hasNotificationBadgeTarget) {
            this.notificationBadgeTarget.classList.add('tw-hidden');
        }
    }
}
