export default class StudyCardHorizontalRenderer {
    constructor(dataLayerTemplate) {
        this.dataLayerTemplate = dataLayerTemplate;
    }

    render(recommendation) {
        return `
<article class="swiper-slide tw-relative tw-flex tw-flex-shrink-0 tw-flex-col tw-h-auto tw-w-[250px] md:tw-w-[300px] tw-rounded-large">     
    <a
        href="${recommendation.tracking_url}"
        class="tw-flex-grow tw-flex tw-flex-col tw-bg-white tw hover:tw-shadow-lg focus:tw-shadow-lg
            tw-relative tw-w-full tw-rounded-large"
        data-layer-event="${this.dataLayerTemplate.event}"
        data-layer-event-cat="${this.dataLayerTemplate.cat}"
        data-layer-event-act="${this.dataLayerTemplate.act}"
        data-layer-event-lab="${this.dataLayerTemplate.lab.replace('{title}', recommendation.title)}"
    >
        <div class="tw-hidden sm:tw-block tw-relative tw-z-10 tw-bg-white tw-rounded-t-large
            tw-border-solid tw-border-gray-400 tw-border"
        >
            <figure>
                <img
                    src="${recommendation.image_url}"
                    alt="${recommendation.title}"
                    class="tw-w-full tw-h-[200px] tw-object-cover tw-rounded-t-large"
                >
            </figure>
        </div>
        <div class="tw-flex tw-flex-col tw-flex-grow tw-px-4 tw-pt-4 tw-pb-6 tw-bg-white
            tw-border sm:tw-border-t-0 tw-rounded-b-large tw-rounded-t-large sm:tw-rounded-t-none tw-border-solid tw-border-gray-400"
        >
            <ul class="tw-flex tw-flex-wrap tw-mb-2 tw-pl-0">
                <li class="tw-bg-black tw-text-white tw-font-heading tw-uppercase tw-text-sm tw-leading-22px tw-px-1 tw-mr-1">
                    Études
                </li>
            </ul>
            <div class="tw-font-heading tw-text-xl">
                ${recommendation.title}
            </div>
        </div>
    </a>
</article>
`;
    }
}
