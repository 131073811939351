export default class QuizHorizontalRenderer {
    constructor(dataLayerTemplate) {
        this.dataLayerTemplate = dataLayerTemplate;
    }

    render(recommendation) {
        return `
<article class="swiper-slide tw-relative tw-flex tw-flex-shrink-0 tw-flex-col tw-h-auto tw-w-[250px] md:tw-w-[300px] tw-rounded-large">     
    <a
        href="${recommendation.tracking_url}"
        class="tw-flex-grow tw-bg-white tw-rounded-large"
        data-layer-event="${this.dataLayerTemplate.event}"
        data-layer-event-cat="${this.dataLayerTemplate.cat}"
        data-layer-event-act="${this.dataLayerTemplate.act}"
        data-layer-event-lab="${this.dataLayerTemplate.lab.replace('{title}', recommendation.title)}"
    >
        <div class="tw-flex tw-h-full hover:tw-shadow-lg focus:tw-shadow-lg tw-rounded-large">
            <div class="tw-p-4 tw-w-full tw-font-heading tw-border-solid tw-border-2 tw-border-primary tw-rounded-l-large">
                <div class="tw-flex tw-mb-2">
                    <div class="tw-text-primary tw-uppercase tw-font-heading tw-text-sm tw-leading-normalBis tw-font-bold">
                        Quiz
                    </div>
                </div>
                <div class="tw-flex">
                    <h2 class="tw-whitespace-normal tw-text-black tw-font-heading tw-text-lg tw-leading-4">
                        ${recommendation.title}
                    </h2>
                </div>
            </div>
            <div class="tw-flex tw-w-6 tw-justify-center tw-items-center tw-relative
                 tw-text-center tw-text-white tw-bg-primary tw-rounded-r-large"
            >
                <i class="fa fa-chevron-right"></i>
            </div>
        </div>
    </a>

    <div class="tw-mt-2 tw-mr-6 tw-px-4 tw-text-sm tw-text-center sm:tw-text-left">
        ${recommendation.sponsor !== '' ? `
            Proposé par <span class="tw-text-piscine-800">${recommendation.sponsor}</span>
        ` : '&nbsp;'}
    </div>
</article>
`;
    }
}
