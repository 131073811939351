export default [
    'bbox.fr',
    'free.fr',
    'gmail.com',
    'gmx.fr',
    'icloud.com',
    'hotmail.fr',
    'live.com',
    'laposte.net',
    'orange.fr',
    'outlook.fr',
    'outlook.com',
    'noos.fr',
    'numericable.fr',
    'msn.fr',
    'voila.fr',
    'wanadoo.fr',
    'sfr.fr',
    'neuf.fr',
    'yahoo.fr',
    'yahoo.com',
];
