import { Controller } from '@hotwired/stimulus';

import { transition } from '../utils/transition';

export default class extends Controller {
    static targets = ['drawer', 'overlay', 'content'];

    static outlets = ['slideover'];

    connect() {
        this.applyTransitionAttributes();
    }

    async open() {
        document.querySelector('body').classList.add('tw-overflow-hidden');

        // In case of nested drawers, we need to prevent the parent drawer from scrolling
        const parentDrawer = this.element.closest('[data-slideover-target="drawer"]');
        if (parentDrawer) {
            let isScrolling;
            const onScroll = () => {
                clearTimeout(isScrolling);
                isScrolling = setTimeout(() => {
                    parentDrawer?.classList.add('tw-overflow-hidden');
                    parentDrawer.removeEventListener('scroll', onScroll);
                }, 150);
            };
            parentDrawer.addEventListener('scroll', onScroll, false);

            parentDrawer.scrollTo({ top: 0, behavior: 'smooth' });
        }

        if (this.hasOverlayTarget) {
            this.overlayTarget.classList.remove('tw-hidden');
        }

        await transition(this.drawerTarget, true);

        this.dispatch('open');
    }

    async close() {
        document.querySelector('body').classList.remove('tw-overflow-hidden');

        if (this.hasOverlayTarget) {
            this.overlayTarget.classList.add('tw-hidden');
        }

        await transition(this.drawerTarget, false);

        const parentDrawer = this.element.closest('[data-slideover-target="drawer"]');
        parentDrawer?.classList.remove('tw-overflow-hidden');

        this.dispatch('close');
    }

    openSlideover({ params }) {
        const openSliderover = (slideover, { contentSelector }) => {
            if (contentSelector && slideover.hasContentTarget) {
                slideover.contentTarget.innerHTML = document.querySelector(contentSelector)?.innerHTML;
            }

            slideover.open();
        };

        if (typeof params.slideoverId === 'undefined') {
            const slideover = this.slideoverOutlets.at(0);

            if (slideover) {
                openSliderover(slideover, params);
            }

            return;
        }

        this.slideoverOutlets.forEach((slideover) => {
            if (slideover.element.id !== params.slideoverId) {
                return;
            }

            openSliderover(slideover, params);
        });
    }

    applyTransitionAttributes() {
        if (!this.hasDrawerTarget) {
            return;
        }

        const animationClasses = 'tw-transition-transform tw-ease-in-out tw-duration-300';
        const hiddenClasses = 'tw-translate-x-full';
        const shownClasses = 'tw-translate-x-0';

        if (!this.drawerTarget.hasAttribute('data-transition-enter')) {
            this.drawerTarget.setAttribute('data-transition-enter', animationClasses);
        }

        if (!this.drawerTarget.hasAttribute('data-transition-enter-from')) {
            this.drawerTarget.setAttribute('data-transition-enter-from', hiddenClasses);
        }

        if (!this.drawerTarget.hasAttribute('data-transition-enter-to')) {
            this.drawerTarget.setAttribute('data-transition-enter-to', shownClasses);
        }

        if (!this.drawerTarget.hasAttribute('data-transition-leave')) {
            this.drawerTarget.setAttribute('data-transition-leave', animationClasses);
        }

        if (!this.drawerTarget.hasAttribute('data-transition-leave-from')) {
            this.drawerTarget.setAttribute('data-transition-leave-from', shownClasses);
        }

        if (!this.drawerTarget.hasAttribute('data-transition-leave-to')) {
            this.drawerTarget.setAttribute('data-transition-leave-to', hiddenClasses);
        }
    }
}
