import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['currentProgress', 'maxProgress', 'percentProgress'];

    static values = {
        currentProgress: Number,
        maxProgress: Number,
    };

    initialize() {
        this.updateProgressbar();
    }

    currentProgressValueChanged() {
        this.updateProgressbar();
    }

    maxProgressValueChanged() {
        this.updateProgressbar();
    }

    updateProgressbar() {
        const percent = (1 - (this.maxProgressValue - this.currentProgressValue) / this.maxProgressValue) * 100;

        this.percentProgressTarget.style.width = `${percent}%`;

        if (this.hasCurrentProgressTarget) {
            this.currentProgressTarget.innerHTML = this.currentProgressValue;
        }

        if (this.hasMaxProgressTarget) {
            this.maxProgressTarget.innerHTML = this.maxProgressValue;
        }
    }
}
