import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['tab', 'tabContent'];

    static classes = ['active', 'inactive'];

    selectTab(e) {
        this.tabContentTargets.forEach((tabContent, index) => {
            // eslint-disable-next-line no-unused-expressions
            index === e.params.tabnumber
                ? tabContent.classList.remove('tw-hidden')
                : tabContent.classList.add('tw-hidden');
        });

        if (this.hasActiveClass) {
            this.tabTargets.forEach((tab, index) => {
                if (index === e.params.tabnumber) {
                    tab.classList.add(...this.activeClasses);
                } else {
                    tab.classList.remove(...this.activeClasses);
                }
            });
        }

        if (this.hasInactiveClass) {
            this.tabTargets.forEach((tab, index) => {
                if (index === e.params.tabnumber) {
                    tab.classList.remove(...this.inactiveClasses);
                } else {
                    tab.classList.add(...this.inactiveClasses);
                }
            });
        }
    }
}
