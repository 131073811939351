import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['count', 'totalCount', 'checkbox'];

    connect() {
        this.updateCount();
    }

    updateCount() {
        const totalCheckbox = this.checkboxTargets.filter((checkbox) => !checkbox.disabled);
        this.totalCountTarget.textContent = totalCheckbox.length;
        this.countTarget.textContent = totalCheckbox.filter((checkbox) => checkbox.checked).length;
    }
}
