import { Controller } from '@hotwired/stimulus';

import { transition } from '../../utils/transition';

export default class extends Controller {
    static targets = ['noResults'];

    delete({ params }) {
        const item = this.element.querySelector(`[data-comparison-id="${params.id}"]`);
        if (item?.getAttributeNames().some((attribute) => attribute.startsWith('data-transition-'))) {
            transition(item, false);
        }

        const showErrorToaster = () => {
            import(/* webpackChunkName: "toaster" */ '../../components/toaster/toaster').then((component) => {
                component.default.display('error', 'Nous ne sommes pas en mesure de supprimer votre comparatif.');
            });
        };

        fetch(params.deleteEndpoint, {
            method: 'DELETE',
            credentials: 'include',
        }).then((response) => {
            if (!response.ok) {
                transition(item, true);

                showErrorToaster();

                // eslint-disable-next-line no-console
                console.error(`Unable to delete comparison "${params.id}"`);

                return;
            }

            item.remove();

            this.toggleNoResults();
        }).catch((error) => {
            transition(item, false);

            showErrorToaster();

            // eslint-disable-next-line no-console
            console.error(`Unable to delete comparison "${params.id}"`, error);
        });
    }

    toggleNoResults() {
        if (this.element.querySelector('.comparison-card') === null) {
            this.noResultsTarget.classList.remove('tw-hidden');
        } else {
            this.noResultsTarget.classList.add('tw-hidden');
        }
    }
}
