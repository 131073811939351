import HorizontalOffset from '../../swiper/plugin/HorizontalOffset';
import SliderProgressbar from '../../swiper/plugin/SliderProgressbar';
import SwiperController from '../swiper-controller';

export default class extends SwiperController {
    static values = {
        offsetFrom: String,
    };

    static outlets = ['progressbar'];

    async createInstance() {
        const { Navigation } = await import('swiper/modules');

        return new this.Swiper(this.element, {
            modules: [Navigation, HorizontalOffset, SliderProgressbar],
            direction: 'horizontal',
            spaceBetween: this.spaceValue,
            slidesPerView: 'auto',
            rewind: true,
            horizontalOffset: {
                from: document.querySelector(this.offsetFromValue),
            },
            sliderProgressbar: {
                threshold: 0.75,
            },
            navigation: {
                nextEl: this.element.querySelector('.swiper-button-next'),
                prevEl: this.element.querySelector('.swiper-button-prev'),
            },
            observer: true,
            on: {
                observerUpdate: () => {
                    this.updateContent();
                },
                slideChange: () => {
                    this.updateContent();
                },
                update: () => {
                    this.updateContent();
                },
                sliderProgressUpdate: (swiper, { progress, maxProgress }) => {
                    this.progressbarOutlet.currentProgressValue = progress;
                    this.progressbarOutlet.maxProgressValue = maxProgress;
                },
            },
        });
    }
}
