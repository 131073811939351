// eslint-disable-next-line max-len
// Source code from https://github.com/excid3/tailwindcss-stimulus-components/blob/1ba935f4256e40a3602c88e30f3db77ae50624c0/src/transition.js

export function nextFrame() {
    return new Promise((resolve) => {
        requestAnimationFrame(() => {
            requestAnimationFrame(resolve);
        });
    });
}

export function afterTransition(element) {
    return Promise.all(element.getAnimations().map((animation) => animation.finished));
}

// class="fixed inset-0 bg-black overflow-y-auto flex items-center justify-center bg-opacity-80 hidden"
// data-transition-enter="transition-all ease-in-out duration-300"
// data-transition-enter-from="bg-opacity-0"
// data-transition-enter-to="bg-opacity-80"
// data-transition-leave="transition-all ease-in-out duration-300"
// data-transition-leave-from="bg-opacity-80"
// data-transition-leave-to="bg-opacity-0"
export async function enter(element) {
    const transitionClasses = element.dataset.transitionEnter || 'tw-transition-all tw-ease-in-out tw-duration-300';
    const fromClasses = element.dataset.transitionEnterFrom || 'enter-from';
    const toClasses = element.dataset.transitionEnterTo || 'enter-to';
    const toggleClass = element.dataset.toggleClass || 'tw-hidden';

    // Prepare transition
    element.classList.add(...transitionClasses.split(' '));
    element.classList.add(...fromClasses.split(' '));
    element.classList.remove(...toClasses.split(' '));
    element.classList.remove(...toggleClass.split(' '));

    await nextFrame();

    element.classList.remove(...fromClasses.split(' '));
    element.classList.add(...toClasses.split(' '));

    try {
        await afterTransition(element);
    } finally {
        element.classList.remove(...transitionClasses.split(' '));
    }
}

export async function leave(element) {
    const transitionClasses = element.dataset.transitionLeave || 'tw-transition-all tw-ease-in-out tw-duration-300';
    const fromClasses = element.dataset.transitionLeaveFrom || 'leave-from';
    const toClasses = element.dataset.transitionLeaveTo || 'leave-to';
    const toggleClass = element.dataset.toggleClass || 'tw-hidden';

    // Prepare transition
    element.classList.add(...transitionClasses.split(' '));
    element.classList.add(...fromClasses.split(' '));
    element.classList.remove(...toClasses.split(' '));

    await nextFrame();

    element.classList.remove(...fromClasses.split(' '));
    element.classList.add(...toClasses.split(' '));

    try {
        await afterTransition(element);
    } finally {
        element.classList.remove(...transitionClasses.split(' '));
        element.classList.add(...toggleClass.split(' '));
    }
}

// Enter transition:
//
//   transition(this.element, true)
//
// Leave transition:
//
//    transition(this.element, false)
export async function transition(element, state) {
    return state ? enter(element) : leave(element);
}
