import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['prevIcon', 'nextIcon', 'prevButton', 'nextButton'];

    static classes = [
        'enabledPrevIcon',
        'enabledNextIcon',
        'disabledPrevIcon',
        'disabledNextIcon',
    ];

    static values = {
        space: { type: Number, default: 10 },
        slides: { type: Number, default: null },
    };

    async connect() {
        this.Swiper = (await import('swiper')).default;

        await this.initSwiper();
    }

    async initSwiper() {
        this.swiper = await this.createInstance();

        this.updateContent();
    }

    async createInstance() {
        const { Navigation } = await import('swiper/modules');

        return new this.Swiper(this.element.querySelector('.swiper') ?? this.element, {
            modules: [Navigation],
            direction: 'horizontal',
            grabCursor: true,
            spaceBetween: this.spaceValue,
            slidesPerView: this.slidesValue ?? 'auto',
            navigation: {
                nextEl: this.element.querySelector('.swiper-button-next'),
                prevEl: this.element.querySelector('.swiper-button-prev'),
            },
            observer: true,
            on: {
                observerUpdate: () => {
                    this.updateContent();
                },
                slideChange: () => {
                    this.updateContent();
                },
                update: () => {
                    this.updateContent();
                },
            },
        });
    }

    updateContent() {
        if (!this.swiper.isBeginning || !this.swiper.isEnd) {
            this.togglePrev();
            this.toggleNext();
        }
    }

    togglePrev() {
        if (this.hasPrevButtonTarget) {
            this.toggleButtonState(this.prevButtonTarget, this.prevIconTarget, this.enabledPrevIconClasses, this.disabledPrevIconClasses);
        }
    }

    toggleNext() {
        if (this.hasNextButtonTarget) {
            this.toggleButtonState(this.nextButtonTarget, this.nextIconTarget, this.enabledNextIconClasses, this.disabledNextIconClasses);
        }
    }

    // eslint-disable-next-line class-methods-use-this
    toggleButtonState(button, icon, enabledClasses, disabledClasses) {
        if (button.hasAttribute('disabled')) {
            icon.classList.remove(...enabledClasses);
            icon.classList.add(...disabledClasses);
        } else {
            icon.classList.remove(...disabledClasses);
            icon.classList.add(...enabledClasses);
        }
    }
}
