import MultiSelect from '@dotburo/multi-select';

const d = document;
let position = 0;
let key = 0;

export default class MultiSelectWithOptionGroup extends MultiSelect {
    constructor(element, id, options = {}) {
        super(element, options);

        this.options.id = id;
    }

    setCurrent(currents = []) {
        const { items } = this.options;
        const { display } = this.options;

        const selectElement = document.querySelector(`#${this.options.id}`);

        if (currents.length === 0) {
            items.forEach((item, index) => {
                this.dom.el.querySelector(`.si-item[data-key="${index}"]`).classList.remove('si-selected');
                item.selected = false;
            });

            selectElement.querySelectorAll('option[selected]').forEach((selectedOption) => {
                selectedOption.removeAttribute('selected');
            });
        } else {
            // clear all elements first, then add only elements passed to the method
            this.setCurrent();

            const convertedItems = this._convertItems(Array.isArray(currents) ? currents : [currents]);

            convertedItems.forEach((current) => {
                items.forEach((item, index) => {
                    if (item[display] === current[display] && item.value === current.value) {
                        this.dom.el.querySelector(`.si-item[data-key="${index}"]`).classList.add('si-selected');
                        item.selected = true;
                        selectElement.querySelector(`option[value="${item.value}"]`).setAttribute('selected', 'selected');
                    }
                });
            });
        }

        this._setResultMessage();
    }

    _renderResultDiv() {
        const el = d.createElement('div');
        el.className = 'si-result';
        el.setAttribute('data-multi-select-target', 'result');

        return el;
    }

    _renderList() {
        const wrap = d.createElement('div');
        const { maxHeight } = this.options;

        wrap.className = 'si-list';
        wrap.setAttribute('data-multi-select-target', 'list');

        if (maxHeight) wrap.style.maxHeight = `${maxHeight}px`;

        let optionGroupKey = 0;
        const items = [];

        this.options.items.forEach((item) => {
            if (typeof items[optionGroupKey] === 'undefined') {
                items[optionGroupKey] = [];
            }

            if (item.isOptionGroupLabel) {
                if (items[0].length !== 0) {
                    // eslint-disable-next-line no-plusplus
                    optionGroupKey++;
                }

                items[optionGroupKey] = [];
                items[optionGroupKey].push(item);
            } else if (item.isOptionGroup) {
                items[optionGroupKey].push(item);
            } else if (!item.isOptionGroupLabel && !item.isOptionGroup) {
                items[optionGroupKey].push(item);
            }
        });

        key = 0;
        position = 0;
        items.forEach(((group) => {
            const el = d.createElement('ul');
            el.innerHTML = this._renderListItems(group);
            wrap.appendChild(el);
        }));

        return wrap;
    }

    _renderListItems(items) {
        const { display } = this.options;
        let list = '';

        for (let i = 0; i < items.length; i++) {
            const selected = items[i].selected ? ' si-selected' : '';
            const margin = items[i].isOptionGroup ? ' si-item-child' : '';
            const dataValue = items[i].value ? `data-value="${items[i].value}"` : '';

            let additionalAttributes = '';
            Object.keys(items[i]).forEach((attributeKey) => {
                if (attributeKey.startsWith('data-')) {
                    additionalAttributes += ` ${attributeKey}="${items[i][attributeKey]}"`;
                }
            });

            list
                += `<li class="si-item${selected}${margin}"
                     data-key="${key}"
                     data-position="${position}"
                     data-index="${i}"
                     data-multi-select-target="listItem"
                     ${dataValue}
                     ${additionalAttributes}
                 >
                    ${items[i][display]}
                </li>`;

            // eslint-disable-next-line no-plusplus
            key++;
        }

        // eslint-disable-next-line no-plusplus
        position++;

        return list;
    }

    _setSelected(currents) {
        const { items } = this.options;
        const { display } = this.options;

        currents.forEach((current) => {
            items.forEach((item) => {
                if (item[display] === current[display] && item.value === current.value) {
                    item.selected = true;
                }
            });
        });
    }

    _setCurrent(e, trigger = true) {
        const el = e.target;
        const elementKey = parseInt(el.dataset.key, 10);
        const item = this.options.items.get(elementKey);

        if (item.isOptionGroupLabel) {
            el.classList.toggle('si-selected');
            item.selected = false;
        } else {
            item.selected = el.classList.toggle('si-selected');
        }

        this.options.items.set(elementKey, item);
        if (trigger) this._trigger('change', item);

        return this;
    }
}
