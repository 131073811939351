/* eslint-disable no-plusplus */
import { afterTransition, nextFrame } from '../../utils/transition';

export default function SliderProgressbar({ extendParams, on }) {
    extendParams({
        sliderProgressbar: {
            threshold: 1,
        },
    });

    async function updateCurrentProgress(swiper) {
        let currentProgress = 0;

        // Wait for transition to end before comparing elements positions
        await nextFrame();
        await afterTransition(swiper.wrapperEl);

        const trackWidth = swiper.el.offsetWidth;

        swiper.slides.forEach((slide) => {
            const boundingClientRect = slide.getBoundingClientRect();

            // Slide is outside current screen (left) or is visible
            if (boundingClientRect.right < trackWidth) {
                currentProgress++;

                return;
            }

            const offsetBox = boundingClientRect.right - trackWidth;
            // If slide's visible width ratio is greater than threshold, it is considered on screen
            if ((boundingClientRect.width - offsetBox) / boundingClientRect.width > swiper.params.sliderProgressbar.threshold) {
                currentProgress++;
            }
        });

        swiper.emit('sliderProgressUpdate', {
            progress: currentProgress,
            maxProgress: swiper.slides.length,
        });
    }

    on('progress', updateCurrentProgress);
}
