const navigationComponent = {
    lastScrollPosition: 0,
    header: document.querySelector('[data-component="header"]'),
    navMobile: document.querySelector('#nav-mobile'),
    init() {
        if (!this.header || this.header.length === 0) {
            return;
        }
        window.addEventListener('scroll', this.onScroll.bind(this));

        if (this.navMobile) {
            const observer = new ResizeObserver((entries) => {
                this.setTopNavMobile(entries[0].target.getBoundingClientRect().height);
            });

            observer.observe(this.header.querySelector('.logo-container'));
        }
    },
    setTopNavMobile(top) {
        this.navMobile?.style?.setProperty('top', `${top}px`);
    },
    onScroll() {
        const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;

        if (currentScrollPosition < 0) {
            return;
        }
        if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 118) {
            return;
        }

        this.showNavbar = currentScrollPosition < this.lastScrollPosition;
        this.lastScrollPosition = currentScrollPosition;

        const header = document.querySelector('.l-header');
        const adsBanner = document.querySelector('[data-adv-formatid="4819"]');

        const classesToAdd = ['sm:tw-static', 'tw-top-0'];

        if (!this.showNavbar) {
            header.classList.remove('show-header');
            header.classList.add('hide-header');
            if (adsBanner) {
                adsBanner.classList.add(...classesToAdd);
            }

            this.setTopNavMobile(0);
        } else {
            header.classList.add('show-header');
            header.classList.remove('hide-header');
            if (adsBanner) {
                adsBanner.classList.remove(...classesToAdd);
            }

            this.setTopNavMobile(header.querySelector('.logo-container').getBoundingClientRect().height);
        }
    },
};

export default navigationComponent.init();
