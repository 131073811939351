import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['checkbox', 'label'];

    static classes = ['disabledLabel'];

    static values = { maxCheckboxes: Number };

    connect() {
        this.check();
    }

    check() {
        const totalCheckedCheckboxes = this.checkboxTargets.reduce((checkedCheckboxes, checkbox) => {
            return checkbox.checked ? checkedCheckboxes + 1 : checkedCheckboxes;
        }, 0);

        // eslint-disable-next-line no-unused-expressions
        totalCheckedCheckboxes >= this.maxCheckboxesValue
            ? this.disableCheckboxes()
            : this.enableCheckboxes();
    }

    disableCheckboxes() {
        this.checkboxTargets.forEach((checkbox) => {
            if (!checkbox.checked) {
                checkbox.disabled = true;
                if (this.hasDisabledLabelClass) {
                    document.querySelector(`label[for=${checkbox.id}]`).classList.add(...this.disabledLabelClasses);
                }
            }
        });
    }

    enableCheckboxes() {
        this.checkboxTargets.forEach((checkbox) => {
            checkbox.removeAttribute('disabled');
            if (this.hasDisabledLabelClass) {
                document.querySelector(`label[for=${checkbox.id}]`).classList.remove(...this.disabledLabelClasses);
            }
        });
    }
}
