const AREA_NOT_KNOWN_SLUG = 'je-ne-sais-pas';

const studyArea = {
    areaNotKnownId: null,
    containerStudyArea: null,
    studyAreaCheckbox: null,
    studyAreaFormSelector: null,
    suffixChildren: null,
    isParent: null,
    handle(additionalData, isParent) {
        if (typeof additionalData.studyAreasSlugs !== 'undefined') {
            const areaNotKnown = additionalData.studyAreasSlugs.find((area) => area.slug === AREA_NOT_KNOWN_SLUG);
            if (areaNotKnown) {
                this.areaNotKnownId = areaNotKnown.id;
            }
        }

        this.isParent = isParent;

        this.suffixChildren = '';
        if (this.isParent) {
            this.suffixChildren = '_children';
        }

        this.studyAreaFormSelector = `study_area${this.suffixChildren}[]`;
        this.containerStudyArea = document.querySelector(`#container-study_area${this.suffixChildren}`);
        this.studyAreaCheckbox = document.getElementsByName(this.studyAreaFormSelector);

        if (this.studyAreaCheckbox.length === 0) {
            return;
        }

        let studyAreasChecked = [];
        this.studyAreaCheckbox.forEach((checkbox) => {
            checkbox.addEventListener('click', (check) => {
                const { value } = check.target;
                const { checked } = check.target;
                // If area is "Je ne sais pas"
                if (value === this.areaNotKnownId) {
                    // Only check this value
                    studyAreasChecked = checked ? [value] : [];
                } else if (checked && !studyAreasChecked.includes(value)) {
                    // Else, remove "Je ne sais pas" area from selected values
                    studyAreasChecked = studyAreasChecked.filter((studyAreaItem) => studyAreaItem !== this.areaNotKnownId);
                    studyAreasChecked.push(value);
                } else {
                    // In case of uncheck, remove area from selected values
                    studyAreasChecked = studyAreasChecked.filter((studyAreaItem) => studyAreaItem !== value);
                }

                this.toggleCheckboxes(studyAreasChecked);
            });
        });
    },
    toggleCheckboxes(values) {
        this.studyAreaCheckbox.forEach((checkbox) => {
            checkbox.checked = !!values.includes(checkbox.value);
        });
    },
    canSubmit(formData) {
        let withoutError = true;
        // No study area checkboxes -> No validation on area
        if (this.studyAreaCheckbox.length === 0) {
            return true;
        }

        if (this.containerStudyArea !== null) {
            const closestContainer = this.containerStudyArea.closest('[data-sso-x-field]');
            if (typeof closestContainer !== 'undefined' && closestContainer.classList.contains('tw-hidden')) {
                return true;
            }
        }

        document.querySelector(`#study_area${this.suffixChildren}-error`).innerHTML = '';

        const studyAreaSelected = formData.getAll(this.studyAreaFormSelector);
        if (studyAreaSelected.length === 0) {
            document.querySelector(`#study_area${this.suffixChildren}-error`).innerHTML = 'Cette valeur est requise';
            withoutError = false;
        }

        return withoutError;
    },
};

export default studyArea;
