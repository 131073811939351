import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['resume', 'coverLetter'];

    connect() {
        this.setDataLayerEventLab();
    }

    setDataLayerEventLab() {
        const existingDocuments = [];
        if (this.resumeTarget.value) {
            existingDocuments.push('CV');
        }

        if (this.coverLetterTarget.value) {
            existingDocuments.push('LM');
        }

        if (existingDocuments.length === 0) {
            this.element.setAttribute('data-layer-event-lab', 'Enregistrer');
        } else {
            this.element.setAttribute('data-layer-event-lab', `Enregistrer - ${existingDocuments.join(' et ')}`);
        }
    }
}
