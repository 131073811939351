import { Controller } from '@hotwired/stimulus';
import axios from 'axios';

import User from '../../components/sso/local_storage/user';
import { transition } from '../../utils/transition';

// Required values:
// data-controller="sso--favorite"
// data-sso--favorite-identifier-value="base 64 encoded string from entity ID, entity trigram, page ID"
// data-sso--favorite-site-value="letudiant" // Or job-board
//
// Optional values:
// data-sso--favorite-mini-value="true" // Or false
// data-sso--favorite-disable-sync-value="true" // Or false
export default class extends Controller {
    static targets = ['trigger'];

    static classes = ['iconActive', 'iconInactive'];

    static values = {
        identifier: String,
        mini: Boolean,
        site: String,
        disableSync: Boolean,
    };

    connect() {
        if (this.disableSyncValue) {
            return;
        }

        this.boundSyncButton = this.syncButton.bind(this);
        document.addEventListener('userSessionUpdated', this.boundSyncButton);

        this.syncButton();
    }

    disconnect() {
        document.removeEventListener('userSessionUpdated', this.boundSyncButton);
    }

    toggle() {
        const user = JSON.parse(sessionStorage.getItem('user'));

        if (!user?.content.connected) {
            window.location.href = `/services/mise-en-favoris/${this.identifierValue}?redirect=${window.location}`;

            return;
        }

        // eslint-disable-next-line max-len
        const isExistingFavorite = user?.content?.favorites?.[this.siteValue]?.some((favorite) => favorite.identifier === this.identifierValue);

        if (isExistingFavorite) {
            this.remove();
        } else {
            this.add();
        }
    }

    remove() {
        this.setDataLayerEventAct('Retirer le favoris');
        this.updateButton(false);

        if (this.element.getAttributeNames().some((attribute) => attribute.startsWith('data-transition-'))) {
            transition(this.element, false);
        }

        axios.request({
            url: `/services/mise-en-favoris/${this.identifierValue}?redirect=${window.location}`,
            method: 'DELETE',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            },
        }).then((response) => {
            const content = response.data;
            if (typeof content.redirect !== 'undefined' && content.redirect !== null) {
                window.location.href = content.redirect;
            } else if (!this.disableSyncValue) {
                User.init();
            }
        }).catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error);
        });
    }

    add() {
        this.updateButton(true);

        axios.request({
            url: `/services/mise-en-favoris/${this.identifierValue}?redirect=${window.location}`,
            method: 'GET',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            },
        }).then((response) => {
            const content = response.data;
            window.dispatchEvent(new CustomEvent('sso--favorite-created', { detail: { showOptinCallModal: content.showOptinCallModal } }));
            if (typeof content.redirect !== 'undefined' && content.redirect !== null) {
                window.location.href = content.redirect;
            } else if (!this.disableSyncValue) {
                User.init();
            }
        }).catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error);
        });
    }

    syncButton() {
        const user = JSON.parse(sessionStorage.getItem('user'));

        // eslint-disable-next-line max-len
        const isExistingFavorite = user?.content?.favorites?.[this.siteValue]?.some((favorite) => favorite.identifier === this.identifierValue);

        this.updateButton(isExistingFavorite);
    }

    updateButton(isExistingFavorite) {
        const iconActiveClasses = this.hasIconActiveClass ? this.iconActiveClasses : ['tw-text-primary'];
        const iconInactiveClasses = this.hasIconInactiveClass ? this.iconInactiveClasses : ['tw-text-gray-700'];

        if (isExistingFavorite) {
            if (this.miniValue) {
                // eslint-disable-next-line max-len
                this.triggerTarget.innerHTML = `<i class="fas fa-heart tw-text-lg ${iconActiveClasses.join(' ')}" title="Retirer des favoris"></i>`;
            } else {
                // eslint-disable-next-line max-len
                this.triggerTarget.innerHTML = `<i class="fas fa-heart tw-text-lg ${iconActiveClasses.join(' ')}"></i><div class="tw-ml-3 tw-font-medium">Retirer des favoris</div>`;
            }
        } else if (this.miniValue) {
            // eslint-disable-next-line max-len
            this.triggerTarget.innerHTML = `<i class="fal fa-heart tw-text-lg ${iconInactiveClasses.join(' ')}" title="Ajouter aux favoris"></i>`;
        } else {
            // eslint-disable-next-line max-len
            this.triggerTarget.innerHTML = `<i class="fal fa-heart tw-text-lg ${iconInactiveClasses.join(' ')}"></i><div class="tw-ml-3 tw-font-medium">Ajouter aux favoris</div>`;
        }
    }

    setDataLayerEventAct(act) {
        if (this.element.hasAttribute('data-layer-event-act')) {
            this.element.setAttribute('data-layer-event-act', act);
        }
    }
}
