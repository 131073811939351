import { Controller } from '@hotwired/stimulus';

import MultiSelectWithOptionGroup from '../components/select/multiSelectComponent/multiSelectWithOptionGroup';

/**
 * Adapted from our components/select/multiSelectComponent/multiSelectComponent.js.
 *
 * This is needed for requalify modal as original component has some difficulties to reload its state when combining with Stimulus.
 */
export default class extends Controller {
    static targets = ['select', 'component', 'list', 'listItem', 'result'];

    connect() {
        this.labels = [];
        this.selectedItems = [];
        this.selectedOptionGroupIndices = [];

        this.instanciateMultiSelect();
    }

    disconnect() {
        // eslint-disable-next-line no-unused-expressions
        this.multiSelect?.remove();
    }

    instanciateMultiSelect() {
        if (!this.hasSelectTarget) {
            return;
        }

        this.computeSelectedItems();

        this.multiSelect = new MultiSelectWithOptionGroup(
            this.componentTarget,
            this.selectTarget.id,
            {
                placeholder: this.selectTarget.getAttribute('placeholder') || 'Choisissez une option',
                items: this.labels,
                display: 'label',
                current: this.selectedItems,
                sort: false,
                order: false,
                more: '(+{X} de plus)',
            },
        );

        this.toggleOptionGroupLabel();

        Object.values(this.selectTarget.children).map((option, index) => {
            if (option.hasAttribute('class')) {
                // eslint-disable-next-line no-unused-expressions
                this.componentTarget.querySelector(`[data-key="${index}"]`)?.classList.add(...Object.values(option.classList));
            }
        });

        // Add event handler on multiselect
        this.multiSelect.on('change', (e) => {
            const target = this.componentTarget.querySelector(`[data-position="${e.detail.key}"][data-index="0"]`);

            if (e.detail.isOptionGroupLabel) {
                if (target.classList.contains('si-selected')) {
                    let elt = target.nextElementSibling;
                    while (elt !== null) {
                        elt.classList.add('si-selected');
                        elt = elt.nextElementSibling;
                    }
                } else {
                    let elt = target.nextElementSibling;
                    while (elt !== null) {
                        elt.classList.remove('si-selected');
                        elt = elt.nextElementSibling;
                    }
                }

                const dataPosition = target.getAttribute('data-position');
                [...this.selectTarget.childNodes[dataPosition].children].forEach((option) => {
                    if (target.classList.contains('si-selected')) {
                        option.setAttribute('selected', 'selected');
                        this.multiSelect.options.items.forEach(((item, key) => {
                            if (item.key === parseInt(dataPosition, 10) && !item.isOptionGroupLabel) {
                                const element = this.multiSelect.options.items.get(key);
                                element.selected = true;
                                this.multiSelect.options.items.set(key, element);
                            }
                        }));
                    } else {
                        option.removeAttribute('selected');
                        this.multiSelect.options.items.forEach(((item, key) => {
                            if (item.key === parseInt(dataPosition, 10) && !item.isOptionGroupLabel) {
                                const element = this.multiSelect.options.items.get(key);
                                element.selected = false;
                                this.multiSelect.options.items.set(key, element);
                            }
                        }));
                    }
                });
            } else if (e.detail.isOptionGroup) {
                const dataPosition = target.getAttribute('data-position');
                const optionsList = this.selectTarget.childNodes[dataPosition].childNodes;
                const parent = this.componentTarget.querySelector(`[data-position="${dataPosition}"][data-index="0"]`);

                if (parent.classList.contains('si-selected')) {
                    parent.classList.remove('si-selected');
                }

                let oneSelected = false;
                Object.values(optionsList).map((option) => {
                    this.setSelected(e, option);
                    if (option.tagName === 'OPTION' && option.hasAttribute('selected')) {
                        oneSelected = true;
                    }
                });

                if (oneSelected) {
                    parent.classList.add('si-selected');
                }
            } else if (e.detail.selected && (e.detail['data-is-unknown-choice'] || '') !== '') {
                const currents = this.multiSelect.getCurrent().filter((value) => {
                    return (value['data-is-unknown-choice'] || '') === e.detail['data-is-unknown-choice'];
                });

                Array.from(this.selectTarget.querySelectorAll('option')).forEach((option) => {
                    if (currents.some((item) => item.value === option.value)) {
                        option.setAttribute('selected', 'selected');
                    } else {
                        option.removeAttribute('selected');
                    }
                });

                this.multiSelect.setCurrent(currents);
            } else {
                const optionsList = this.selectTarget.childNodes;
                Object.values(optionsList).map((option) => {
                    this.setSelected(e, option);
                });
            }

            // trigger the change event on the original HTML select in case we want to bind listeners to it
            this.selectTarget.dispatchEvent(new Event('change'));
        });

        this.multiSelect.on('clear', () => {
            this.multiSelect.setCurrent();
        });

        this.selectTarget.addEventListener('sync-options', this.syncOptions.bind(this));

        window.dispatchEvent(new CustomEvent('watch-select', { detail: { selectElement: this.selectTarget } }));
    }

    computeSelectedItems() {
        this.labels = [];
        this.selectedItems = [];
        this.selectedOptionGroupIndices = [];

        Object.values(this.selectTarget.children).map((option, index) => {
            if (option.tagName === 'OPTGROUP') {
                const selectGroupItem = this.constructSelectList({
                    option,
                    index,
                    isOptionGroupLabel: true,
                });
                if (selectGroupItem) {
                    this.selectedItems.push(selectGroupItem);
                }

                const parentLabel = option.label;
                let oneSelected = false;
                Object.values(option.children).map((optionGroupItem) => {
                    const selectItem = this.constructSelectList({
                        option: optionGroupItem,
                        index,
                        isOptionGroupLabel: false,
                        isOptionGroup: true,
                        parentLabel,
                    });
                    if (selectItem) {
                        this.selectedItems.push(selectItem);
                        oneSelected = true;
                    }
                });

                if (oneSelected) {
                    this.selectedOptionGroupIndices.push(index);
                }
            } else {
                const selectItem = this.constructSelectList({
                    option,
                    index,
                });
                if (selectItem) {
                    this.selectedItems.push(selectItem);
                }
            }
        });
    }

    constructSelectList({
        option,
        index,
        isOptionGroupLabel = false,
        isOptionGroup = false,
        parentLabel = '',
    }) {
        const optionAttributes = option.getAttributeNames();
        const additionalDataKeys = {};

        optionAttributes.forEach((attributeName) => {
            if (attributeName.startsWith('data-')) {
                additionalDataKeys[attributeName] = option.getAttribute(attributeName);
            }
        });

        let label = '';
        if (isOptionGroupLabel) {
            label = this.getLabel(option.label);
        } else {
            label = this.getLabel(option.textContent);
        }

        const itemData = {
            value: option.value,
            label: label,
            key: index,
            isOptionGroupLabel: isOptionGroupLabel,
            isOptionGroup: isOptionGroup,
            parentLabel: parentLabel,
        };

        this.labels.push({ ...itemData, ...additionalDataKeys });

        if (option.getAttribute('selected') && !isOptionGroupLabel) {
            return this.labels.find((element) => element.label === this.getLabel(option.textContent) && element.value === option.value);
        }

        return null;
    }

    toggleOptionGroupLabel() {
        this.listItemTargets.forEach((item) => {
            if (!item.hasAttribute('data-position')) {
                return;
            }

            const position = parseInt(item.getAttribute('data-position'), 10);
            if (this.selectedOptionGroupIndices.includes(position)) {
                item.classList.add('si-selected');
            } else if (this.selectedItems.find((selectedItem) => item.value === selectedItem.value) === false) {
                item.classList.remove('si-selected');
            }
        });
    }

    setSelected(event, option) {
        // eslint-disable-next-line max-len
        if (event.detail.label === this.getLabel(option.textContent) && option.getAttribute('selected') !== 'selected' && event.detail.value === option.value) {
            option.setAttribute('selected', 'selected');

            return;
        }

        // eslint-disable-next-line max-len
        if (event.detail.label === this.getLabel(option.textContent) && option.getAttribute('selected') === 'selected' && event.detail.value === option.value) {
            option.removeAttribute('selected');
        }
    }

    getLabel(label) {
        return label.replace(/<[^>]*>?/gm, '');
    }

    syncOptions() {
        this.computeSelectedItems();

        this.multiSelect.setCurrent(this.selectedItems);
        this.toggleOptionGroupLabel();
    }
}
