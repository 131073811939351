import flatpickr from 'flatpickr';
import { French } from 'flatpickr/dist/l10n/fr';

import dataLayerEvents from '../../dataLayerEvents/dataLayerEvents';
import emailDomainSuggester from '../../emailDomainSuggester/emailDomainSuggester';
import formErrors from './formErrors';
import formFields from './formFields';
import ssoSecondStep from './ssoSecondStep';

const ID_FAMILY_PARTNER = 6;

const ssoFirstStep = {
    actionFirstStep: null,
    ssoSecondStepFormContainer: null,
    ssoReturnToLoginPageButton: null,
    ssoSubmitFirstStepButton: null,
    ssoFirstStepForm: null,
    ssoConnexionContainer: null,
    profiles: null,
    async buildFirstStepForm() {
        let dom = '';

        // Display main title
        if (document.querySelector('#sso-main-title')) {
            document.querySelector('#sso-main-title').classList.remove('tw-hidden');
        }

        this.ssoFirstStepForm = document.querySelector('#sso-first-step-form');
        this.ssoConnexionContainer = document.querySelector('#sso-connexion-container');
        const ssoFormConfDiv = document.querySelector('#sso-first-step-form-conf');
        const formConf = JSON.parse(ssoFormConfDiv.textContent);
        this.actionFirstStep = MY_LETUDIANT_URL + ssoFormConfDiv.getAttribute('data-sso-x-host-register-first-step');

        formConf.content.fields.forEach((field) => {
            if (field.id === 'profile') {
                this.profiles = field;
            }

            if (field.id === 'profile_family' && field.profile_family_filter === null) {
                return;
            }

            if (field.id === 'profile_family') {
                field.options = field.options.filter((item) => {
                    return item.value !== ID_FAMILY_PARTNER;
                });
            }

            dom += formFields.getDom(field);
        });

        // Get buttons return and submit
        dom += formFields.getActionsFirstStep();

        // Push new dom
        this.ssoFirstStepForm.innerHTML = dom;

        // Set event to family profiles input
        formConf.content.fields.forEach((field) => {
            if (field.id === 'profile_family' && field.profile_family_filter !== null) {
                if (Object.keys(field.profile_family_filter).length === 1) {
                    const containerProfileFamily = document.querySelector('#container-profile_family');
                    const profileFamilyLabel = document.querySelector('#profile_family-label');
                    if (containerProfileFamily && profileFamilyLabel) {
                        profileFamilyLabel.classList.add('tw-hidden');
                        containerProfileFamily.classList.add('tw-hidden');
                        containerProfileFamily.classList.remove('sm:tw-flex');
                    }
                } else {
                    this.handleProfileFamily(field);
                    this.hideProfile();

                    const profileLabel = document.querySelector('#profile-label');
                    if (profileLabel) {
                        profileLabel.classList.add('tw-hidden');
                    }
                }
            }
        });

        // Set event to passwords & autocomplete input
        formFields.handlePassword();
        formFields.handleAutocomplete();

        const zipcode = document.querySelector('#zipcode');
        if (zipcode) {
            zipcode.placeholder = 'ex: 78000';
        }

        // Init return and submit button event
        this.ssoReturnToLoginPageButton = document.querySelector('#sso-return-to-login');
        if (this.ssoReturnToLoginPageButton) {
            this.ssoReturnToLoginPageButton.addEventListener('click', () => {
                if (document.querySelector('#sso-main-title')) {
                    document.querySelector('#sso-main-title').classList.add('tw-hidden');
                }
                this.returnToLoginPage();
            });
        }

        this.ssoSubmitFirstStepButton = document.querySelector('#sso-submit-first-step');
        if (this.ssoSubmitFirstStepButton) {
            this.ssoSubmitFirstStepButton.addEventListener('click', () => {
                this.submitFirstStep();
            });
        }

        // Display div which contains form and hidden login/register div
        this.ssoConnexionContainer.classList.add('tw-hidden', 'tw-w-full', 'tw-mt-12');
        this.ssoFirstStepForm.classList.remove('tw-hidden');
        this.ssoFirstStepForm.classList.add('tw-flex', 'tw-flex-col', 'tw-w-full', 'tw-mt-12');

        // Display breadcrumb
        document.querySelector('#sso-breadcrumb').classList.remove('tw-hidden');

        emailDomainSuggester.init();

        flatpickr(document.querySelector('[id=birthdate]'), {
            dateFormat: 'd/m/Y',
            minDate: '01/01/1900',
            maxDate: new Date().setFullYear(new Date().getFullYear() - 10),
            locale: French,
            disableMobile: true,
        });
    },
    returnToLoginPage() {
        document.querySelector('#sso-breadcrumb').classList.add('tw-hidden');
        this.ssoConnexionContainer.classList.remove('tw-hidden');
        this.ssoConnexionContainer.classList.add('tw-flex', 'tw-w-full', 'tw-mt-12');
        this.ssoFirstStepForm.classList.add('tw-hidden', 'tw-flex-col', 'tw-w-full', 'tw-mt-12');
        this.ssoFirstStepForm.classList.remove('tw-flex');
    },
    submitFirstStep() {
        const formData = new FormData(document.querySelector('#sso-first-step-form'));
        formData.append('need_parental_consent', '0');
        formData.delete('profile_family');

        const request = new XMLHttpRequest();
        request.open('POST', this.actionFirstStep);
        request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        request.send(formData);

        request.onreadystatechange = () => {
            if (request.readyState === XMLHttpRequest.DONE) {
                const response = JSON.parse(request.responseText);

                if (typeof response.errors !== 'undefined') {
                    formErrors.handleErrors(response.errors.fields);
                } else {
                    if (typeof window.dataLayer !== 'undefined') {
                        window.dataLayer.push(dataLayerEvents.getEventParameters(this.ssoSubmitFirstStepButton));
                    }
                    window.scroll(0, 0);
                    ssoSecondStep.buildSecondStepForm(response);
                }
            }
        };
    },
    handleProfileFamily(field) {
        document.querySelector('#sso-first-step-form').addEventListener('change', () => {
            const formData = new FormData(document.querySelector('#sso-first-step-form'));
            const profileFamilyValue = formData.get('profile_family');

            if (profileFamilyValue !== null) {
                this.hideProfile(field.profile_family_filter[profileFamilyValue]);
            }
        });

        const profileFamilyRadios = document.getElementsByName('profile_family');

        profileFamilyRadios.forEach((radio) => {
            radio.addEventListener('change', () => {
                const radios = document.getElementsByName('profile');

                for (let i = 0; i < radios.length; i++) {
                    if (radios[i].checked) {
                        radios[i].checked = false;
                    }
                }
            });
        });
    },
    hideProfile(profilesToShow = []) {
        if (this.profiles) {
            this.profiles.options.forEach((option) => {
                const profileRadio = document.querySelector(`#profile_${option.value}`);
                const radioContainer = document.querySelector(`#radio-profile_${option.value}`);
                if (radioContainer) {
                    radioContainer.classList.add('tw-hidden');
                }

                if (profilesToShow.includes(option.value.toString())) {
                    if (profilesToShow.length === 1) {
                        profileRadio.checked = true;
                        const profileError = document.querySelector('#profile-error');
                        if (profileError) {
                            profileError.classList.add('tw-hidden');
                        }

                        const profileLabel = document.querySelector('#profile-label');
                        if (profileLabel) {
                            profileLabel.classList.add('tw-hidden');
                        }
                    } else {
                        radioContainer.classList.remove('tw-hidden');
                        const profileError = document.querySelector('#profile-error');
                        if (profileError) {
                            profileError.classList.remove('tw-hidden');
                        }

                        const profileLabel = document.querySelector('#profile-label');
                        if (profileLabel) {
                            profileLabel.classList.remove('tw-hidden');
                        }
                    }
                }
            });
        }
    },
};

export default ssoFirstStep;
