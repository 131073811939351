import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['firstStep', 'secondStep', 'required', 'requiredChoice', 'counter'];

    connect() {
        this.requiredTargets.forEach((input) => {
            input.addEventListener('input', () => {
                this.validateField(input);
            });
        });

        this.requiredChoiceTargets.forEach((element) => {
            element.addEventListener('change', () => {
                this.validateChoiceFields(element);
            });
        });

        this.updateCounter();
    }

    updateCounter() {
        const count = this.counterTarget.querySelectorAll('input[type="checkbox"]:checked').length;
        const counterContainer = this.counterTarget.querySelector('.counter-container');

        counterContainer.innerText = count;
        counterContainer.classList.toggle('tw-hidden', !count);
    }

    showSecondStep() {
        if (this.validateFirstStep()) {
            this.firstStepTarget.classList.add('tw-hidden');
            this.secondStepTarget.classList.remove('tw-hidden');
        }

        this.element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }

    backToFirstStep() {
        this.secondStepTarget.classList.add('tw-hidden');
        this.firstStepTarget.classList.remove('tw-hidden');
    }

    validateFirstStep() {
        let isValid = true;
        this.requiredTargets.forEach((input) => {
            if (!this.validateField(input)) {
                isValid = false;
            }
        });

        this.requiredChoiceTargets.forEach((element) => {
            if (!this.validateChoiceFields(element)) {
                isValid = false;
            }
        });

        return isValid;
    }

    validateField(input) {
        const errorMessage = input.closest('.sso-form-row').querySelector('.error-message');

        if (input.value === '') {
            input.classList.add('!tw-border-danger');
            errorMessage?.classList.remove('tw-hidden');
            return false;
        }

        input.classList.remove('!tw-border-danger');
        errorMessage?.classList.add('tw-hidden');

        return true;
    }

    validateChoiceFields(element) {
        const counter = element.querySelectorAll('input:checked').length;
        const errorField = element.querySelector('.error-field');
        const errorMessage = element.querySelector('.error-message');

        if (counter === 0) {
            errorField.classList.add('tw-border-danger');
            errorField.classList.remove('tw-border-gray-300');
            errorMessage?.classList.remove('tw-hidden');
        } else {
            errorField.classList.remove('tw-border-danger');
            errorField.classList.add('tw-border-gray-300');
            errorMessage?.classList.add('tw-hidden');
        }

        return counter > 0;
    }
}
