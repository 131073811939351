import { Controller } from '@hotwired/stimulus';

/**
 * Update avatar and username based on current user.
 */
export default class extends Controller {
    static targets = ['avatar', 'username'];

    static values = {
        avatarSmall: Boolean,
    };

    connect() {
        this.boundUpdateView = this.updateView.bind(this);
        document.addEventListener('userSessionUpdated', this.boundUpdateView);

        this.updateView();
    }

    disconnect() {
        document.removeEventListener('userSessionUpdated', this.boundUpdateView);
    }

    updateView() {
        const user = JSON.parse(sessionStorage.getItem('user'));
        if (!user?.content?.connected) {
            this.avatarTargets.forEach((avatar) => {
                if (!avatar.hasAttribute('data-default-avatar')) {
                    return;
                }

                // eslint-disable-next-line no-param-reassign
                avatar.src = avatar.getAttribute('data-default-avatar');
            });

            this.toggleUsername(false);

            return;
        }

        const userData = user.content.user;
        if (userData?.picture) {
            const avatarPath = userData.picture;
            const avatarPathMini = userData.picture.replace('_x140', '_x35');
            this.avatarTargets.forEach((avatar) => {
                if (!avatar.hasAttribute('data-default-avatar')) {
                    avatar.setAttribute('data-default-avatar', avatar.src);
                }

                // eslint-disable-next-line no-param-reassign
                avatar.src = this.avatarSmallValue ? avatarPathMini : avatarPath;
            });
        }

        this.toggleUsername(true, userData.firstname);
    }

    toggleUsername(isConnected, firstname) {
        this.usernameTargets.forEach((element) => {
            if (isConnected) {
                if (!element.hasAttribute('data-original-content')) {
                    element.setAttribute('data-original-content', element.innerHTML);
                }

                // eslint-disable-next-line no-param-reassign
                element.innerHTML = firstname;

                return;
            }

            if (element.hasAttribute('data-original-content')) {
                // eslint-disable-next-line no-param-reassign
                element.innerHTML = element.getAttribute('data-original-content');
            }
        });
    }
}
