import datePickerComponent from '../../datePickerComponent/datePickerComponent';
import formErrors from './formErrors';
import formFields from './formFields';
import specialitiesAndStream from './specialitiesAndStream';
import studyArea from './studyArea';

const ssoQualifyStep = {
    additionalData: null,
    isParentStudyArea: false,
    isParentStudyLevel: false,
    hasPrintOptinMessage: false,
    studyStreamFieldConf: [],
    buildQualifyStepForm() {
        let dom = '';
        const ssoFormConfDiv = document.querySelector('#sso-qualify-step-form-conf');
        const ssoQualifyStepForm = document.querySelector('#sso-qualify-step-form');
        const formConf = JSON.parse(ssoFormConfDiv.textContent);
        this.additionalData = formConf.additionalData;

        if (typeof formConf.fields === 'undefined') {
            formConf.form.view.fields.forEach((field) => {
                dom += this.getDom(field);
            });
        } else {
            formConf.fields.forEach((field) => {
                dom += this.getDom(field);
            });
        }

        dom += formFields.getActionQualify();

        ssoQualifyStepForm.innerHTML = dom;

        if (datePickerComponent !== false) {
            datePickerComponent.displayDatePicker();
        }

        specialitiesAndStream.handle(this.additionalData, this.studyStreamFieldConf, this.isParentStudyLevel);
        studyArea.handle(this.additionalData, this.isParentStudyArea);

        formFields.handleAutocomplete();

        this.ssoSubmitFirstStepButton = document.querySelector('#sso-qualify-submit-button');
        if (this.ssoSubmitFirstStepButton) {
            this.ssoSubmitFirstStepButton.addEventListener('click', () => {
                this.submitQualifyStep();
            });
        }
    },
    getDom(field) {
        let dom = '';
        if (field.id === 'study_area' || field.id === 'study_area_children') {
            this.isParentStudyArea = field.id.includes('_children');
        }

        if (field.id === 'study_stream' || field.id === 'study_stream_children') {
            this.studyStreamFieldConf = field;
            this.isParentStudyLevel = field.id.includes('_children');
        }

        if (!this.hasPrintOptinMessage && field.id.includes('optin')) {
            this.hasPrintOptinMessage = true;
            // eslint-disable-next-line max-len
            dom += "<p>En validant mon inscription, j'atteste avoir plus de 15 ans ou avoir obtenu l’autorisation de mes parents ou tuteurs légaux :</p>";
        }

        return dom + formFields.getDom(field);
    },
    submitQualifyStep() {
        const formData = new FormData(document.querySelector('#sso-qualify-step-form'));

        if (typeof this.additionalData !== 'undefined' && !specialitiesAndStream.canSubmit(formData)) {
            return;
        }

        const request = new XMLHttpRequest();

        request.open('POST', window.location.href);
        request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        request.send(formData);

        request.onreadystatechange = () => {
            if (request.readyState === XMLHttpRequest.DONE) {
                const response = JSON.parse(request.responseText);

                if (typeof response.errors !== 'undefined' && response.errors !== null) {
                    formErrors.handleErrors(response.errors.fields);
                } else {
                    window.location.replace(response.referer);
                }
            }
        };
    },
};

export default ssoQualifyStep;
