export default class RecommendationRenderer {
    /**
     * @param {Object} renderers renderers indexed by their supporting type
     */
    constructor(renderers) {
        this.renderers = renderers;
    }

    /**
     * Convert each recommendation items (objects) to HTML view (string) if a renderer is available for given item's type.
     *
     * @param {Object[]} recommendations
     * @returns {string}
     */
    renderHtml(recommendations = []) {
        return recommendations.map((recommendation) => this.renderers[recommendation.type]?.render(recommendation)).join('');
    }
}
