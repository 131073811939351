import { Controller } from '@hotwired/stimulus';

const optionSelector = "[role='option']:not([aria-disabled])";
const activeSelector = "[aria-selected='true']";

const debounce = (fn, delay = 10) => {
    let timeoutId = null;

    return () => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(fn, delay);
    };
};

export default class extends Controller {
    static targets = ['input', 'results', 'error', 'inputClear'];

    static values = {
        minLength: Number,
        url: String,
        fetchOnLoad: { type: Boolean, default: true },
        delay: { type: Number, default: 300 },
        queryParam: { type: String, default: 'q' },
    };

    connect() {
        if (!this.inputTarget.hasAttribute('autocomplete')) {
            this.inputTarget.setAttribute('autocomplete', 'off');
        }

        this.inputTarget.setAttribute('spellcheck', 'false');

        this.hideClearTarget();

        this.boundOnInputChange = debounce(this.onInputChange.bind(this), this.delayValue);
        this.inputTarget.addEventListener('input', this.boundOnInputChange);

        if (this.fetchOnLoadValue) {
            this.onInputChange();
        }
    }

    disconnect() {
        this.inputTarget.removeEventListener('input', this.boundOnInputChange);
    }

    onInputChange() {
        const query = this.inputTarget.value.trim();

        this.hideClearTarget();

        if (query && query.length >= this.minLengthValue) {
            this.fetchResults(query);
        }
    }

    hideClearTarget() {
        const query = this.inputTarget.value.trim();
        if (query && this.hasInputClearTarget) {
            this.inputClearTarget.classList.remove('tw-hidden');
        }
    }

    async fetchResults(query) {
        if (!this.hasUrlValue) {
            return;
        }

        const url = this.buildURL(query);
        try {
            this.element.dispatchEvent(new CustomEvent('loadstart'));
            const html = await this.doFetch(url);
            this.replaceResults(html);
            this.element.dispatchEvent(new CustomEvent('load'));
            this.element.dispatchEvent(new CustomEvent('loadend'));
        } catch (error) {
            this.element.dispatchEvent(new CustomEvent('error'));
            this.element.dispatchEvent(new CustomEvent('loadend'));
            throw error;
        }
    }

    async doFetch(url) {
        const response = await fetch(url, {
            headers: { 'X-Requested-With': 'XMLHttpRequest', Accept: 'text/html' },
        });

        if (!response.ok) {
            throw new Error(`Server responded with status ${response.status}`);
        }

        return response.text();
    }

    buildURL(query) {
        const url = new URL(this.urlValue, window.location.href);
        const params = new URLSearchParams(url.search.slice(1));
        params.append(this.queryParamValue, query);
        url.search = params.toString();

        return url.toString();
    }

    replaceResults(html) {
        this.resultsTarget.innerHTML = html;
        if (this.options) {
            this.open();
        } else {
            this.close();
        }
    }

    select(item) {
        const params = item.params;

        this.inputTarget.value = params.label;

        this.resultsTarget.querySelectorAll(activeSelector).forEach((option) => {
            option.classList.remove('tw-bg-gray-100');
        });

        item.currentTarget.classList.add('tw-bg-gray-100');
        item.currentTarget.setAttribute('aria-selected', 'true');

        this.close();
    }

    focus() {
        this.inputTarget.focus();
    }

    open() {
        if (this.resultsShown) {
            return;
        }

        this.resultsShown = true;
        this.element.setAttribute('aria-expanded', 'true');
        this.element.dispatchEvent(
            new CustomEvent('toggle', {
                detail: { action: 'open', inputTarget: this.inputTarget, resultsTarget: this.resultsTarget },
            }),
        );
    }

    close() {
        if (!this.resultsShown) {
            return;
        }

        this.resultsShown = false;
        this.inputTarget.removeAttribute('aria-activedescendant');
        this.element.setAttribute('aria-expanded', 'false');
        this.element.dispatchEvent(
            new CustomEvent('toggle', {
                detail: { action: 'close', inputTarget: this.inputTarget, resultsTarget: this.resultsTarget },
            }),
        );
    }

    clear() {
        this.inputTarget.value = '';
        this.inputTarget.dispatchEvent(new Event('input'));
        this.inputTarget.dispatchEvent(new Event('change'));

        this.focus();

        // eslint-disable-next-line no-unused-expressions
        this.hasInputClearTarget && this.inputClearTarget.classList.add('tw-hidden');
    }

    get resultsShown() {
        return !this.resultsTarget.hidden;
    }

    set resultsShown(value) {
        this.resultsTarget.hidden = !value;
    }

    get options() {
        return Array.from(this.resultsTarget.querySelectorAll(optionSelector));
    }
}
