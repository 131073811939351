import Masonry from 'masonry-layout';

const masonryGrid = {
    grids: document.querySelectorAll('[data-component=masonryGrid]'),
    gridSizers: null,
    init() {
        if (this.grids.length === 0) {
            return;
        }
        this.setupGrid();
    },
    setupGrid() {
        const observerConfig = { attributes: false, childList: true, subtree: false };

        this.gridSizers = document.querySelectorAll('.masonry-grid-sizer');
        for (let i = 0; i < this.grids.length; i++) {
            const masonry = new Masonry(this.grids[i], {
                columnWidth: this.gridSizers[i],
                itemSelector: '.masonry-grid-item',
                percentPosition: true,
                horizontalOrder: true,
            });

            masonry.layout();

            const masonryItems = this.grids[i].querySelectorAll('.card img.lazyload');
            const resizeObserver = new ResizeObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.target.classList.contains('lazyloaded')) {
                        setTimeout(() => {
                            masonry.layout();
                        }, 300);
                        resizeObserver.unobserve(entry.target);
                    }
                });
            });

            masonryItems.forEach((masonryItem) => {
                resizeObserver.observe(masonryItem);
            });

            const lazyload = document.querySelectorAll('[data-lazyload]');

            if (typeof lazyload !== 'undefined') {
                const isInViewport = (elem) => {
                    const bounding = elem.getBoundingClientRect();
                    return (
                        bounding.top >= 0
                        && bounding.left >= 0
                        && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
                        && bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
                    );
                };

                Object.values(lazyload).map((elem) => {
                    window.addEventListener('scroll', () => {
                        if (isInViewport(elem)) {
                            masonry.layout();
                        }
                    });
                });
            }

            const adDiv = this.grids[i].querySelector('div[id^="sas_"]');
            if (adDiv !== null) {
                const domChangeCallback = (mutationList) => {
                    // eslint-disable-next-line no-restricted-syntax
                    for (const mutation of mutationList) {
                        if (mutation.type === 'childList') {
                            setTimeout(() => {
                                masonry.layout();
                            }, 1500);
                        }
                    }
                };
                const observer = new MutationObserver(domChangeCallback);
                observer.observe(adDiv, observerConfig);
            }
        }
    },
};

export default masonryGrid;
