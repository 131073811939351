import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['block', 'checkbox', 'label', 'checkmark'];

    static classes = ['activeBlock', 'activeLabel'];

    connect() {
        this.applyCheckboxStyle();
    }

    toggleCheckboxState() {
        if (this.checkboxTarget.disabled) {
            return;
        }
        this.checkboxTarget.checked = !this.checkboxTarget.checked;
        this.applyCheckboxStyle();
    }

    applyCheckboxStyle() {
        // eslint-disable-next-line no-unused-expressions
        this.checkboxTarget.checked ? this.applyActiveStyle() : this.applyInactiveStyle();
    }

    applyActiveStyle() {
        if (this.hasActiveBlockClass) {
            this.blockTarget.classList.add(...this.activeBlockClasses);
        }
        if (this.hasActiveLabelClass) {
            this.labelTarget.classList.add(...this.activeLabelClasses);
        }
        if (this.hasCheckmarkTarget) {
            this.checkmarkTarget.classList.remove('tw-hidden');
        }
    }

    applyInactiveStyle() {
        if (this.hasActiveBlockClass) {
            this.blockTarget.classList.remove(...this.activeBlockClasses);
        }
        if (this.hasActiveLabelClass) {
            this.labelTarget.classList.remove(...this.activeLabelClasses);
        }
        if (this.hasCheckmarkTarget) {
            this.checkmarkTarget.classList.add('tw-hidden');
        }
    }
}
