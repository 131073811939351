import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        name: { type: String, default: 'France' },
    };

    nameValueChanged(currentName, oldName) {
        if (currentName === oldName) {
            return;
        }

        this.element.value = currentName;
    }
}
