import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static outlets = ['jsa--advert-dropdown'];

    static targets = ['actionTrigger'];

    static values = {
        endpoint: String,
        status: String,
    };

    async connect() {
        if (typeof window.luxon === 'undefined') {
            // This dependency is required by tabulator-tables and its `date` filter
            window.luxon = await import('luxon');
        }

        const { TabulatorFull } = await import('tabulator-tables');

        this.Tabulator = TabulatorFull;

        await this.setupTabulator();

        const boundUpdateColumnWidth = this.updateColumnWidth.bind(this);

        this.resizeObserver = new ResizeObserver(boundUpdateColumnWidth);
        this.resizeObserver.observe(this.element);
    }

    disconnect() {
        this.resizeObserver.disconnect();
    }

    async showActions(event) {
        this.jsaAdvertDropdownOutlet.idValue = event.params.advert.id;
        this.jsaAdvertDropdownOutlet.showLinkValue = event.params.advert.url;

        this.jsaAdvertDropdownOutlet.show();
        // We need to display dropdown to get its offsetWidth
        this.jsaAdvertDropdownOutlet.menuTarget.style.left = `${event.pageX - this.jsaAdvertDropdownOutlet.menuTarget.offsetWidth}px`;
        this.jsaAdvertDropdownOutlet.menuTarget.style.top = `${event.pageY + event.target.offsetHeight}px`;
    }

    hideActions(event) {
        this.jsaAdvertDropdownOutlet.hide(event);
    }

    async setupTabulator() {
        const { default: responsiveCollapse } = await import('../../tabulator-tables/responsiveCollapse');
        const { default: langs } = await import('../../tabulator-tables/langs');

        this.actionTriggerTemplate = this.actionTriggerTarget.cloneNode(true);

        this.table = new this.Tabulator(this.element, {
            locale: 'fr',
            langs,
            ajaxURL: this.endpointValue,
            pagination: true,
            paginationMode: 'remote',
            filterMode: 'remote',
            sortMode: 'remote',
            placeholder: "Vous n'avez pas d'annonces",
            headerSortElement: (column, dir) => {
                switch (dir) {
                    case 'asc':
                        return '<i class="fal fa-arrow-alt-up tw-text-xl tw-text-primary"></i>';
                    case 'desc':
                        return '<i class="fal fa-arrow-alt-down tw-text-xl tw-text-primary"></i>';
                    default:
                        return '<i class="fal fa-sort tw-text-xl tw-text-primary"></i>';
                }
            },
            layout: 'fitData',
            responsiveLayout: 'collapse',
            responsiveLayoutCollapseStartOpen: false,
            columns: [
                {
                    field: 'responsiveCollapse',
                    formatter: responsiveCollapse,
                    width: 50,
                    hozAlign: 'center',
                    responsive: 0,
                    resizable: false,
                    headerSort: false,
                },
                {
                    title: "Nom de l'annonce",
                    field: 'label',
                    responsive: 0,
                    resizable: false,
                    headerFilter: 'input',
                    headerFilterPlaceholder: "Taper un nom d'annonce",
                },
                {
                    title: "Type d'annonce",
                    field: 'type',
                    resizable: false,
                    headerFilter: 'list',
                    headerFilterPlaceholder: "Taper un nom d'annonce",
                    headerFilterParams: {
                        values: {
                            '': 'Pas de filtre',
                            'jobs-etudiants': 'Job',
                            'offre-alternance': 'Alternance',
                            'stages-etudiants': 'Stage',
                            emploi: 'Emploi',
                        },
                    },
                },
                {
                    title: this.statusValue === 'published' ? 'Date de publication' : "Date d'archivage",
                    field: 'date',
                    format: 'dd/MM/yyyy',
                    resizable: false,
                    headerFilter: 'date',
                    headerFilterPlaceholder: 'Taper une date',
                    headerFilterParams: {
                        format: 'iso',
                    },
                },
                {
                    title: 'Lieu(x)',
                    field: 'locations',
                    formatter: 'html',
                    resizable: false,
                },
                {
                    title: 'Contrat(s)',
                    field: 'contracts',
                    resizable: false,
                },
                {
                    title: 'Niveau(x)',
                    field: 'levels',
                    resizable: false,
                },
                {
                    title: 'Nombre de postes',
                    field: 'job_count',
                    resizable: false,
                },
                {
                    title: 'Référence',
                    field: 'id',
                    resizable: false,
                },
                {
                    title: 'Actions',
                    field: 'actions',
                    responsive: 0,
                    formatter: (cell) => {
                        const data = cell.getRow().getData();

                        const trigger = this.actionTriggerTemplate.cloneNode(true);

                        // Allow dropdown to get advert data and calculate links
                        trigger.setAttribute('data-jsa--recruiter-adverts-advert-param', JSON.stringify({
                            id: data.id,
                            url: data.url,
                        }));

                        return trigger;
                    },
                    resizable: false,
                    headerSort: false,
                    minWidth: 80,
                    maxWidth: 80,
                },
            ],
        });

        this.table.on('renderComplete', () => {
            this.updateColumnWidth();
        });
    }

    updateColumnWidth() {
        if (!this.table.initialized) {
            return;
        }

        const responsiveCollapseElement = this.table.getColumn('responsiveCollapse').getElement();
        const actionsElement = this.table.getColumn('actions').getElement();

        const getHorizontalBorderWidth = (element) => {
            const computedStyle = getComputedStyle(element);

            return (parseInt(computedStyle.borderLeftWidth, 10) || 0) + (parseInt(computedStyle.borderRightWidth, 10) || 0);
        };

        const getElementWidth = (element) => element.offsetWidth + getHorizontalBorderWidth(element);

        const getColumnWidth = (displayableColumns) => {
            const availableWidth = this.element.offsetWidth
                - getHorizontalBorderWidth(this.element)
                - getElementWidth(responsiveCollapseElement)
                - getElementWidth(actionsElement);
            return (availableWidth / displayableColumns);
        };

        let visibleColumns = 0;

        let displayableColumns = 3;
        if (window.innerWidth <= 540) {
            displayableColumns = 1;
        } else if (window.innerWidth <= 640) {
            displayableColumns = 2;
        }

        this.table.getColumns().forEach((column) => {
            if (['responsiveCollapse', 'actions'].includes(column.getField())) {
                return;
            }

            if (visibleColumns < displayableColumns) {
                const columnWidth = getColumnWidth(displayableColumns);

                column.setWidth(columnWidth);

                // eslint-disable-next-line no-plusplus
                visibleColumns++;
            } else {
                column.setWidth(true);
            }
        });
    }

    refreshData() {
        this.table.setData();
    }
}
