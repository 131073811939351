import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        orders: Array,
    };

    async connect() {
        if (typeof window.luxon === 'undefined') {
            // This dependency is required by tabulator-tables and its `date` filter
            window.luxon = await import('luxon');
        }

        const { TabulatorFull } = await import('tabulator-tables');

        this.Tabulator = TabulatorFull;

        await this.setupTabulator();

        const boundUpdateColumnWidth = this.updateColumnWidth.bind(this);

        this.resizeObserver = new ResizeObserver(boundUpdateColumnWidth);
        this.resizeObserver.observe(this.element);
    }

    disconnect() {
        this.resizeObserver.disconnect();
    }

    async setupTabulator() {
        const { default: responsiveCollapse } = await import('../../tabulator-tables/responsiveCollapse');
        const { default: langs } = await import('../../tabulator-tables/langs');

        this.table = new this.Tabulator(this.element, {
            locale: 'fr',
            langs,
            placeholder: "Vous n'avez pas de facture pour cette annonce.",
            headerSortElement: (column, dir) => {
                switch (dir) {
                    case 'asc':
                        return '<i class="fal fa-arrow-alt-up tw-text-xl tw-text-primary"></i>';
                    case 'desc':
                        return '<i class="fal fa-arrow-alt-down tw-text-xl tw-text-primary"></i>';
                    default:
                        return '<i class="fal fa-sort tw-text-xl tw-text-primary"></i>';
                }
            },
            layout: 'fitData',
            responsiveLayout: 'collapse',
            responsiveLayoutCollapseStartOpen: false,
            columns: [
                {
                    field: 'responsiveCollapse',
                    formatter: responsiveCollapse,
                    width: 50,
                    hozAlign: 'center',
                    responsive: 0,
                    resizable: false,
                    headerSort: false,
                },
                {
                    title: 'Référence',
                    field: 'reference',
                    responsive: 0,
                    resizable: false,
                    headerFilter: 'input',
                    headerFilterPlaceholder: "Saisissez une référence d'annonce",
                },
                {
                    title: 'Date de paiement',
                    field: 'date',
                    formatter: 'datetime',
                    formatterParams: {
                        inputFormat: 'iso',
                        outputFormat: 'dd/MM/yy',
                        timezone: 'Europe/Paris',
                    },
                    resizable: false,
                    headerFilter: 'date',
                    headerFilterPlaceholder: 'Saisissez une date',
                    headerFilterParams: {
                        format: 'dd/MM/yyyy',
                    },
                    cssClass: '!tw-border-r-0',
                },
                {
                    title: 'Montant',
                    field: 'amount',
                    formatter: 'money',
                    formatterParams: {
                        decimal: ',',
                        thousand: ' ',
                        symbol: '€',
                        symbolAfter: true,
                    },
                    resizable: false,
                },
                {
                    title: 'Facture',
                    field: 'invoice_url',
                    formatter: (cell) => {
                        return `<a class="tw-text-primary tw-underline tw-italic" href=${cell.getValue()}>Télécharger</a>`;
                    },
                    resizable: false,
                },
            ],
            data: this.ordersValue,
        });

        this.table.on('renderComplete', () => {
            this.updateColumnWidth();
        });
    }

    updateColumnWidth() {
        if (!this.table.initialized) {
            return;
        }

        const responsiveCollapseElement = this.table.getColumn('responsiveCollapse').getElement();

        const getHorizontalBorderWidth = (element) => {
            const computedStyle = getComputedStyle(element);

            return (parseInt(computedStyle.borderLeftWidth, 10) || 0) + (parseInt(computedStyle.borderRightWidth, 10) || 0);
        };

        const getElementWidth = (element) => element.offsetWidth + getHorizontalBorderWidth(element);

        const getColumnWidth = (displayableColumns) => {
            const availableWidth = this.element.offsetWidth
                - getHorizontalBorderWidth(this.element)
                - getElementWidth(responsiveCollapseElement);
            return (availableWidth / displayableColumns);
        };

        let visibleColumns = 0;

        let displayableColumns = 2;
        if (window.innerWidth <= 540) {
            displayableColumns = 1;
        }

        this.table.getColumns().forEach((column) => {
            if (['responsiveCollapse'].includes(column.getField())) {
                return;
            }

            if (visibleColumns < displayableColumns) {
                const columnWidth = getColumnWidth(displayableColumns);

                column.setWidth(columnWidth);

                // eslint-disable-next-line no-plusplus
                visibleColumns++;
            } else {
                column.setWidth(true);
            }
        });

        this.table.columnManager.layoutRefresh();
    }
}
