import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['subscriptionBtn', 'pending'];

    static values = {
        endpoint: String,
        subscribeMsg: { type: String, default: 'Recevoir des notifications' },
        unsubscribeMsg: { type: String, default: 'Ne plus recevoir de notifications' },
        subscribed: { type: Boolean, default: false },
        pending: { type: Boolean, default: false },
    };

    connect() {
        this.updateSubscriptionBtnStyle();
    }

    toggleSubscription() {
        if (!this.pendingValue) {
            this.pendingValue = true;
            this.callApi(this.subscribedValue ? 'DELETE' : 'GET');
        }
    }

    callApi(method) {
        this.subscribedValue = !this.subscribedValue;
        this.updateSubscriptionBtnStyle();
        this.pendingTarget.classList.remove('tw-hidden');

        fetch(this.endpointValue, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            if (!response.ok) {
                throw new Error(`${response.status}`);
            }
            this.updateDataLayerEventLab();
        }).finally(() => {
            this.pendingValue = false;
            this.pendingTarget.classList.add('tw-hidden');
        });
    }

    updateSubscriptionBtnStyle() {
        if (this.subscribedValue) {
            this.subscriptionBtnTarget.classList.remove('tw-bg-primary');
            this.subscriptionBtnTarget.classList.add('tw-bg-gray-600');
            this.subscriptionBtnTarget.textContent = this.unsubscribeMsgValue;
        } else {
            this.subscriptionBtnTarget.classList.add('tw-bg-primary');
            this.subscriptionBtnTarget.classList.remove('tw-bg-gray-600');
            this.subscriptionBtnTarget.textContent = this.subscribeMsgValue;
        }
    }

    updateDataLayerEventLab() {
        if (this.subscribedValue) {
            this.subscriptionBtnTarget.setAttribute('data-layer-event-lab', this.unsubscribeMsgValue);
        } else {
            this.subscriptionBtnTarget.setAttribute('data-layer-event-lab', this.subscribeMsgValue);
        }
    }
}
